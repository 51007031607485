export const PATHS = {
    SETUP: 'setup',
    PLAYLIST_VISIBILITY: 'playlist-visibility',
    SHARE_PROFILE: 'share-profile',
};
export const QUERY_KEY = {
    PHONE_NUMBER_VERIFIED: 'phone-number-verified',
};
export const QUERY_VALUE = {
    TRUE: 'true',
};
export const QUERY = {
    PHONE_NUMBER_VERIFIED: `${QUERY_KEY.PHONE_NUMBER_VERIFIED}=${QUERY_VALUE.TRUE}`,
};
// This is used to close the webview and navigate to browse home. The music app will ignore the domain,
// so we don't need to make this domain specific.
export const BROWSE_HOME = 'https://music.amazon.com/';
