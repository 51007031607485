import { Component } from 'preact';
// Recommended to not add history as direct dependency to avoid version conflicts breaking the singleton behavior
// https://reactrouter.com/docs/en/v6/routers/history-router
import history from 'history/browser'; // eslint-disable-line import/no-extraneous-dependencies
import { getCustomerInfo, setPreferenceForOwner } from '../native/AppInterface';
import { getUserPlaylists, updatePlaylist } from '../services/firefly/FireFlyInterface';
import {
    Playlist,
    PreferenceKey,
    PreferenceTarget,
    PreferenceValue,
    VisibilityType,
} from '../types';
import {
    getStrings,
    LocalizedStrings,
    QUERY_KEY,
    QUERY_VALUE,
    PATHS,
} from '../util';

interface State {
    userPlaylists: Playlist[]
    oAuthToken: string
    deviceId: string
    deviceType: string
    strings: LocalizedStrings
}

function isPrivate(playlist: Playlist) {
    return playlist.visibility === VisibilityType.PRIVATE;
}

export default class ProfileVisibilityPage extends Component<object, State> {
    private async setup() {
        const {
            oAuthToken,
            deviceId,
            deviceType,
            locale,
        } = await getCustomerInfo();
        const userPlaylists = (await getUserPlaylists(
            oAuthToken,
            deviceId,
            deviceType,
            20,
        )).filter(isPrivate);
        if (new URLSearchParams(window.location.search).get(QUERY_KEY.PHONE_NUMBER_VERIFIED) === QUERY_VALUE.TRUE) {
            // TODO: Emit error metric if failure
            setPreferenceForOwner(
                PreferenceKey.PHONE_NUMBER_VERIFIED,
                PreferenceTarget.ALL,
                PreferenceValue.ENABLED,
            );
        }
        if (userPlaylists.length === 0) {
            // No playlists to make public - continue to share profile page
            history.replace(`/${PATHS.SHARE_PROFILE}`);
        }
        this.setState({
            userPlaylists,
            oAuthToken,
            deviceId,
            deviceType,
            strings: getStrings(locale),
        });
    }

    constructor(props) {
        super(props);
        this.setup();
    }

    private async makePlaylistPublic(playlist: Playlist, index: number) {
        if (!isPrivate(playlist)) {
            // Playlist is already public. Do nothing.
            return;
        }
        const updatePlaylistPromise = updatePlaylist(
            this.state.oAuthToken,
            this.state.deviceId,
            this.state.deviceType,
            playlist.id,
            playlist.title,
            playlist.description,
            VisibilityType.PUBLIC,
        );
        const { userPlaylists } = this.state;
        userPlaylists[index].visibility = VisibilityType.PUBLIC;
        this.setState({ userPlaylists });
        try {
            await updatePlaylistPromise;
        } catch (error) {
            // TODO: Add error metric here
            // Update failed - change button back to make public.
            userPlaylists[index].visibility = VisibilityType.PRIVATE;
            this.setState({ userPlaylists });
        }
    }

    private formatPlaylistDescription(playlist: Playlist, strings: LocalizedStrings) {
        const hours = Math.floor(playlist.duration / 3600);
        const minutes = Math.floor(playlist.duration / 60) % 60;
        const seconds = playlist.duration % 60;
        if (playlist.trackCount === 0) {
            return strings.publicPlaylistNoSongs;
        }
        const songText = playlist.trackCount === 1
            ? strings.publicPlaylistOneSong
            : strings.publicPlaylistMultipleSongs;

        let durationText;
        if (hours > 1) {
            durationText = strings.publicPlaylistDurationMultipleHours;
        } else if (hours === 1) {
            durationText = strings.publicPlaylistDurationOneHour;
        } else {
            durationText = strings.publicPlaylistDuration;
        }
        return durationText
            .replace('{songCount}', playlist.trackCount)
            .replace('{songText}', songText)
            .replace('{minutes}', minutes)
            .replace('{hours}', hours)
            .replace('{seconds}', seconds);
    }

    private finish() {
        history.push(`/${PATHS.SHARE_PROFILE}`);
    }

    private getPlaylistRows() {
        return this.state.userPlaylists.map((playlist, index) => <music-image-row
            imageSrc={playlist.images[0].url}
            primaryText={playlist.title}
            secondaryText1={this.formatPlaylistDescription(playlist, this.state.strings)}>
            <music-button
                slot='buttons'
                variant={isPrivate(playlist) ? 'glass' : 'solid'}
                removeTextTransform={true}
                onclick={this.makePlaylistPublic.bind(this, playlist, index)}
                size='small'>
                <span class='playlist-button-text'>{isPrivate(playlist)
                    ? this.state.strings.publicPlaylistMakePublic
                    : this.state.strings.publicPlaylistPublic}
                </span>
            </music-button>
        </music-image-row>);
    }

    render() {
        // Wait until state is loaded before rendering content.
        if (!this.state.strings) {
            return <div class='loading-spinner-wrapper'>
                <music-icon size="xl" name="loader"/>
            </div>;
        }
        return <div id="setup-profile" class='page-contents'>
            <header class='profile-header'>
                <h1 class='music-headline-3'>{this.state.strings.publicPlaylistHeader}</h1>
                <div class='secondary-text subtext'>{this.state.strings.publicPlaylistSubHeader}</div>
            </header>
            {this.getPlaylistRows.bind(this)()}
            <music-button class='primary-button'
                variant='solid'
                onclick={this.finish.bind(this)}
                size='large'>{this.state.strings.done}
            </music-button>
        </div>;
    }
}
