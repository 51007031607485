import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { FireFlyError } from '../../types';

export interface GraphQLBody {
    query: string,
}

export interface BaseRequestParameters {
    accessToken: string,
    deviceId: string,
    deviceType: string,
}

/**
 *  FireFly Beta endpoint
 *  TODO: Replace this endpoint with FireFly Prod endpoint after all the changes are deployed to Prod.
 */
const gqlEndpoint = 'https://beta.gql.music.amazon.dev';

/**
 *  Security profile ID for Growth-ContactImport to authenticate with FireFly service.
 *  Reference:
 *  https://gitlab.aws.dev/musicfirefly/fireflyservicecdk/-/blob/main/lib/MusicfireFlyServiceClientCdkStack.ts#L121
 */
const apiKey = 'amzn1.application.fa0fb22a8edf497e9a2da7e1ad8ce89b';

const timeoutMilliseconds = 10000;

export class FireFlyServiceClient {
    /**
     * Query FireFly's GraphQL endpoint.
     */
    public async query(gqlBody: GraphQLBody, requestParams: BaseRequestParameters): Promise<AxiosResponse> {
        const headers = this.getAuthorizationHeaders(requestParams);
        const axiosConfig: AxiosRequestConfig = {
            headers,
            method: 'post',
            url: gqlEndpoint,
            data: JSON.stringify(gqlBody),
            timeout: timeoutMilliseconds,
        };

        return this.makeRequest(axiosConfig);
    }

    public async makeRequest(axiosConfig: AxiosRequestConfig): Promise<AxiosResponse> {
        try {
            return await axios(axiosConfig);
        } catch (exception) {
            throw new FireFlyError(
                `FireFly service exception:\n${exception}\nfor Request:\n${JSON.stringify(axiosConfig)}`,
            );
        }
    }

    public getAuthorizationHeaders({ accessToken, deviceId, deviceType }: BaseRequestParameters) {
        if (!accessToken || accessToken.length === 0) {
            throw new FireFlyError('Missing AccessToken. Cannot construct authorization headers for FireFly request.');
        }

        let headers: { [key: string]: string };
        if (deviceId && deviceId.length > 0 && deviceType && deviceType.length > 0) {
            // First Party Auth: Atna Authentication flow
            const authHeader = {
                access_token: accessToken,
                deviceId,
                deviceType,
            };
            const encoded = window.btoa(JSON.stringify(authHeader));
            headers = {
                Authorization: `AmznMusic ${encoded}`,
                'x-api-key': apiKey,
            };
        } else {
            // Third Party Auth: Atza/ LoginWithAmazon Authentication flow
            headers = {
                Authorization: `Bearer ${accessToken}`,
                'x-api-key': apiKey,
            };
        }
        return headers;
    }
}
