import { AxiosResponse } from 'axios';
import {
    Image,
    ImageType,
    Playlist,
    User,
    VisibilityType,
    FireFlyError,
    UpdatePlaylistParams,
    UpdateUserParams,
} from '../../types';
import { BaseRequestParameters, FireFlyServiceClient, GraphQLBody } from './fireflyServiceClient';

interface GraphQlImage {
    url: string;
    width?: number;
    height?: number;
    imageType?: ImageType;
}

interface GraphQlUser {
    id: string;
    name: string;
    images: GraphQlImage[];
    visibility: VisibilityType;
    playbackVisibility?: VisibilityType;
    url?: string;
}

interface GraphQlPlaylist {
    id: string;
    title: string;
    duration: number;
    trackCount: number;
    visibility: VisibilityType;
    images: GraphQlImage[];
    curator: User;
    description?: string;
    url?: string;
}

interface GqlPlaylistNode {
    node: GraphQlPlaylist
}

const userQueryBody = `{
    id
    name
    images { url }
    visibility
    playbackVisibility
}`;

const playlistQueryBody = `{
    id
    title
    duration
    trackCount
    visibility
    images { url }
    description
}`;

export class FireFlyServiceHelper {
    private fireflyClient: FireFlyServiceClient;

    constructor(fireflyClient: FireFlyServiceClient) {
        this.fireflyClient = fireflyClient;
    }

    public async getUser(requestParams: BaseRequestParameters) : Promise<User> {
        const request = this.getUserRequest();
        const response = await this.fireflyClient.query(request, requestParams);
        this.checkResponseStatus(response);

        // Get User
        if (!response?.data?.data?.user) {
            throw new FireFlyError(`FireFly Response doesn't contain User:\n${JSON.stringify(response)}`);
        }
        return this.getUserFromGqlUser(response?.data?.data?.user);
    }

    public async updateUser(
        requestParams: BaseRequestParameters,
        updateUserParams: UpdateUserParams,
    ) : Promise<User> {
        const request = this.updateUserRequest(updateUserParams);
        const response = await this.fireflyClient.query(request, requestParams);
        this.checkResponseStatus(response);

        // Get Updated User
        if (!response?.data?.data?.updateUser) {
            throw new FireFlyError(`FireFly Response doesn't contain UpdateUser:\n${JSON.stringify(response)}`);
        }
        return this.getUserFromGqlUser(response?.data?.data?.updateUser);
    }

    public async getUserPlaylists(
        requestParams: BaseRequestParameters,
        limit?: number,
    ): Promise<Playlist[]> {
        const request = this.getUserPlaylistsRequest(limit);
        const response = await this.fireflyClient.query(request, requestParams);
        this.checkResponseStatus(response);

        // Get User Playlists
        if (!response?.data?.data?.user?.playlists?.edges) {
            throw new FireFlyError(`FireFly Response doesn't contain Playlist Edge:\n${JSON.stringify(response)}`);
        }
        return this.getPlaylistsFromGqlPlaylistNodes(response?.data?.data?.user?.playlists?.edges);
    }

    public async updatePlaylist(
        requestParams: BaseRequestParameters,
        updatePlaylistParams: UpdatePlaylistParams,
    ) : Promise<Playlist> {
        const request = this.updatePlaylistRequest(updatePlaylistParams);
        const response = await this.fireflyClient.query(request, requestParams);
        this.checkResponseStatus(response);

        // Get Updated Playlist
        if (!response?.data?.data?.updatePlaylist) {
            throw new FireFlyError(`FireFly Response doesn't contain Playlist:\n${JSON.stringify(response)}`);
        }
        return this.getPlaylistFromGqlPlaylist(response?.data?.data?.updatePlaylist);
    }

    public checkResponseStatus(response: AxiosResponse) {
        if (response?.data?.errors) {
            throw new FireFlyError(`FireFly Error Response:\n${JSON.stringify(response)}`);
        }
    }

    private getImagesFromGqlImages(gqlImages: GraphQlImage[]): Image[] {
        const images : Image[] = [];
        if (!gqlImages) {
            return images;
        }

        gqlImages.forEach((gqlImage) => {
            images.push(new Image({
                url: gqlImage.url,
            }));
        });
        return images;
    }

    private getUserFromGqlUser(gqlUser: GraphQlUser): User {
        return new User({
            id: gqlUser.id,
            name: gqlUser.name,
            images: this.getImagesFromGqlImages(gqlUser.images),
            visibility: gqlUser.visibility,
            playbackVisibility: gqlUser.playbackVisibility,
        });
    }

    private getPlaylistsFromGqlPlaylistNodes(gqlPlaylistNodes: GqlPlaylistNode[]): Playlist[] {
        const playlists : Playlist[] = [];
        gqlPlaylistNodes.forEach((gqlNode) => {
            playlists.push(this.getPlaylistFromGqlPlaylist(gqlNode.node));
        });
        return playlists;
    }

    private getPlaylistFromGqlPlaylist(gqlPlaylist: GraphQlPlaylist): Playlist {
        return new Playlist({
            id: gqlPlaylist.id,
            title: gqlPlaylist.title,
            duration: gqlPlaylist.duration,
            trackCount: gqlPlaylist.trackCount,
            visibility: gqlPlaylist.visibility,
            images: this.getImagesFromGqlImages(gqlPlaylist.images),
            description: gqlPlaylist.description,
        });
    }

    public getUserRequest(): GraphQLBody {
        const query = `
            query {
                user ${userQueryBody}
            }`;
        return {
            query,
        };
    }

    public updateUserRequest(inputParams: UpdateUserParams): GraphQLBody {
        const { name, visibility, playbackVisibility } = inputParams;

        const params = this.formatParams({ name, visibility, playbackVisibility });
        const query = `
            mutation {
                updateUser ${params} ${userQueryBody}
            }`;
        return {
            query,
        };
    }

    public getUserPlaylistsRequest(limit?: number): GraphQLBody {
        const query = `
            query {
                user {
                    playlists (limit: ${limit}) {
                        edges {
                            node ${playlistQueryBody}
                        }
                    }}}`;
        return {
            query,
        };
    }

    public updatePlaylistRequest(inputParams: UpdatePlaylistParams): GraphQLBody {
        const {
            playlistId,
            title,
            description,
            visibility,
        } = inputParams;

        const params = this.formatParams({
            playlistId,
            title,
            description,
            visibility,
        });
        const query = `
            mutation {
                updatePlaylist ${params} ${playlistQueryBody}
            }`;
        return {
            query,
        };
    }

    private formatParams(paramDictionary: Record<string, string|undefined>): string {
        const args : string[] = [];
        Object.entries(paramDictionary).forEach(([key, val]) => {
            if (val) {
                args.push(`${key}: ${JSON.stringify(val)}`);
            }
        });
        return args.length > 0 ? `(${args.join(', ')})` : '';
    }
}
