export interface LocalizedStrings {
    setupProfileHeader: string
    setupProfileSubHeader: string
    setupProfileProfileName: string
    setupProfileNameDescription: string
    setupProfileLearnMore: string
    setupProfileListeningHistoryTitle: string
    setupProfileListeningHistoryDescription: string
    setupProfileContactDiscoveryTitle: string
    setupProfileContactDiscoveryDescription: string
    setupProfilePublicRadioTitle: string
    setupProfilePublicRadioDescription: string
    setupProfilePrivateRadioTitle: string
    setupProfilePrivateRadioDescription: string
    setupProfileSubmitButton: string
    setupProfileContactDiscoveryLearnMoreDescription: string
    setupProfileLearnMoreGotIt: string
    setupProfileNameLearnMoreTitle: string
    setupProfileNameLearnMoreDescription: string
    publicPlaylistHeader: string
    publicPlaylistSubHeader: string
    publicPlaylistMakePublic: string
    publicPlaylistPublic: string
    publicPlaylistSkip: string
    publicPlaylistDurationMultipleHours: string
    publicPlaylistDurationOneHour: string
    publicPlaylistDuration: string
    publicPlaylistNoSongs: string
    publicPlaylistOneSong: string
    publicPlaylistMultipleSongs: string
    shareProfileHeader: string
    shareProfileSubHeader: string
    shareProfile: string
    done: string
}

interface Strings {
    en_US: LocalizedStrings
    es_US: LocalizedStrings
    en_CA: LocalizedStrings
    fr_CA: LocalizedStrings
    es_MX: LocalizedStrings
    es_CO: LocalizedStrings
    es_CL: LocalizedStrings
    pt_BR: LocalizedStrings
    en_GB: LocalizedStrings
    de_DE: LocalizedStrings
    fr_FR: LocalizedStrings
    it_IT: LocalizedStrings
    es_ES: LocalizedStrings
    ja_JP: LocalizedStrings
    en_AU: LocalizedStrings
    en_IN: LocalizedStrings
    hi_IN: LocalizedStrings

}

// Disable max-len for strings to prevent eslint from requiring translations to be split into strings on multiple lines
// and simplify adding strings to this file.
/* eslint-disable max-len */
const strings: Strings = {
    en_US: {
        // Setup Profile Page
        setupProfileHeader: 'Set Up Profile',
        setupProfileSubHeader: 'To share your playlists, your Amazon Music profile must be public',
        setupProfileProfileName: 'Profile Name',
        setupProfileNameDescription: 'Changes to your name or photo here also change your Amazon profile. ',
        setupProfileLearnMore: 'Learn more',
        setupProfileListeningHistoryTitle: 'Music listening activity',
        setupProfileListeningHistoryDescription: 'Let followers see what you\'ve been listening to and when.',
        setupProfileContactDiscoveryTitle: 'Contact discovery',
        setupProfileContactDiscoveryDescription: 'Let people with your phone number find you across Amazon services.',
        setupProfilePublicRadioTitle: 'Public',
        setupProfilePublicRadioDescription: 'People can see your name, photo, and public playlists. They can also search for and follow your profile.',
        setupProfilePrivateRadioTitle: 'Private',
        setupProfilePrivateRadioDescription: 'People only see your name and photo. Your profile is hidden from search results.',
        setupProfileSubmitButton: 'Continue',
        setupProfileContactDiscoveryLearnMoreDescription: 'This setting affects all Amazon services that access your contacts. If you turn this off, you won’t be able to use the calling or Drop In features on Alexa. To grant permission to individual services, go to More>Settings>Communication>Manage Contacts>Mobile Number in the Alexa app.',
        setupProfileLearnMoreGotIt: 'Got it',
        setupProfileNameLearnMoreTitle: 'Amazon Profiles',
        setupProfileNameLearnMoreDescription: 'Your Amazon Profile includes things like name, picture, reviews, and posts that other Amazon customers can see as they shop. To change what\'s public in your profile, you can edit privacy settings.',
        // Make Playlists Public Page
        publicPlaylistHeader: 'Make your playlists public?',
        publicPlaylistSubHeader: 'Followers will see them on your profile.',
        publicPlaylistMakePublic: 'MAKE PUBLIC',
        publicPlaylistPublic: 'PUBLIC',
        publicPlaylistSkip: 'Skip',
        publicPlaylistDurationMultipleHours: '{songCount} {songText}  •  {hours} hrs {minutes} min',
        publicPlaylistDurationOneHour: '{songCount} {songText}  •  {hours} hr {minutes} min',
        publicPlaylistDuration: '{songCount} {songText}  •  {minutes} min {seconds} sec',
        publicPlaylistNoSongs: 'No songs',
        publicPlaylistOneSong: 'song',
        publicPlaylistMultipleSongs: 'songs',
        // Share Profile Page
        shareProfileHeader: 'SHARE YOUR PROFILE',
        shareProfileSubHeader: 'Let friends know where to find your public playlists.',
        shareProfile: 'Share',
        // General
        done: 'Done',
    },
    es_US: {
        setupProfileHeader: 'Configurar perfil',
        setupProfileSubHeader: 'Para compartir tus playlists, tu perfil de Amazon Music debe ser público',
        setupProfileProfileName: 'Nombre de perfil',
        setupProfileNameDescription: 'Los cambios que hagas aquí en tu nombre o foto también se reflejarán en tu perfil de Amazon. ',
        setupProfileLearnMore: 'Más información',
        setupProfileListeningHistoryTitle: 'Actividad de escucha de música',
        setupProfileListeningHistoryDescription: 'Deja que tus seguidores vean lo que escuchaste',
        setupProfileContactDiscoveryTitle: 'Descubrir contactos',
        setupProfileContactDiscoveryDescription: 'Permite a las personas que tengan tu número de teléfono encontrar tu perfil en los servicios de Amazon. ',
        setupProfilePublicRadioTitle: 'Público',
        setupProfilePublicRadioDescription: 'La gente puede ver tu nombre, foto y playlists públicas. También puede seguir tu perfil.',
        setupProfilePrivateRadioTitle: 'Privado',
        setupProfilePrivateRadioDescription: 'La gente solo ve tu nombre y tu foto.',
        setupProfileSubmitButton: 'Continuar',
        setupProfileContactDiscoveryLearnMoreDescription: 'Esta configuración afecta a todos los servicios de Amazon que acceden a tus contactos. Si la desactivas, no podrás usar las funciones de llamada ni de Drop In en Alexa. Para otorgar permiso para servicios individuales, ve a Más>Configuración>Comunicación>Administrar contactos>Número de celular en la app de Alexa.',
        setupProfileLearnMoreGotIt: 'Entendido',
        setupProfileNameLearnMoreTitle: 'Perfiles de Amazon',
        setupProfileNameLearnMoreDescription: 'Tu perfil de Amazon incluye nombre, foto, reseñas y posts que otros clientes de Amazon pueden ver al comprar productos. Puedes modificar esto en ajustes de privacidad.',
        // Make Playlists Public Page
        publicPlaylistHeader: '¿Quieres hacer públicas tus playlists?',
        publicPlaylistSubHeader: 'Los seguidores las verán en tu perfil.',
        publicPlaylistMakePublic: 'HACER PÚBLICA',
        publicPlaylistPublic: 'PÚBLICA',
        publicPlaylistSkip: 'Omitir',
        publicPlaylistDurationMultipleHours: '{songCount} {songText}  •  {hours} h {minutes} min',
        publicPlaylistDurationOneHour: '{songCount} {songText}  •  {hours} h {minutes} min',
        publicPlaylistDuration: '{songCount} {songText}  •  {minutes} min {seconds} s',
        publicPlaylistNoSongs: 'No hay canciones',
        publicPlaylistOneSong: 'canción',
        publicPlaylistMultipleSongs: 'canciones',
        // Share Profile Page
        shareProfileHeader: 'COMPARTE TU PERFIL',
        shareProfileSubHeader: 'Deja que tus amigos sepan dónde encontrar tus playlists públicas.',
        shareProfile: 'Compartir',
        // General
        done: 'Listo',
    },
    en_CA: {
        // Setup Profile Page
        setupProfileHeader: 'Set up profile',
        setupProfileSubHeader: 'To share your playlists, your Amazon Music profile must be public',
        setupProfileProfileName: 'Profile Name',
        setupProfileNameDescription: 'Changes to your name or photo here will also change your Amazon profile. ',
        setupProfileLearnMore: 'Learn more',
        setupProfileListeningHistoryTitle: 'Music listening activity',
        setupProfileListeningHistoryDescription: 'Let followers see what you\'ve been listening to',
        setupProfileContactDiscoveryTitle: 'Contact discovery',
        setupProfileContactDiscoveryDescription: 'Let people with your phone number find your profile across Amazon services. ',
        setupProfilePublicRadioTitle: 'Public',
        setupProfilePublicRadioDescription: 'People can see your name, photo and public playlists. They can also follow your profile.',
        setupProfilePrivateRadioTitle: 'Private',
        setupProfilePrivateRadioDescription: 'People can only see your name and photo.',
        setupProfileSubmitButton: 'Continue',
        setupProfileContactDiscoveryLearnMoreDescription: 'This setting affects all Amazon services that access your contacts. If you turn this off, you won’t be able to use the calling or Drop In features on Alexa. To grant permission to individual services, go to More>Settings>Communication>Manage Contacts>Mobile Number in the Alexa app.',
        setupProfileLearnMoreGotIt: 'Got it',
        setupProfileNameLearnMoreTitle: 'Amazon Profiles',
        setupProfileNameLearnMoreDescription: 'Your Amazon Profile includes things like name, picture, reviews, and posts that other Amazon customers can see as they shop. To change what\'s public in your profile, you can edit privacy settings.',
        // Make Playlists Public Page
        publicPlaylistHeader: 'Make your playlists public?',
        publicPlaylistSubHeader: 'Followers will see them on your profile.',
        publicPlaylistMakePublic: 'MAKE PUBLIC',
        publicPlaylistPublic: 'PUBLIC',
        publicPlaylistSkip: 'Skip',
        publicPlaylistDurationMultipleHours: '{songCount} {songText}  •  {hours} hrs {minutes} min',
        publicPlaylistDurationOneHour: '{songCount} {songText}  •  {hours} hr {minutes} min',
        publicPlaylistDuration: '{songCount} {songText}  •  {minutes} min {seconds} sec',
        publicPlaylistNoSongs: 'No songs',
        publicPlaylistOneSong: 'song',
        publicPlaylistMultipleSongs: 'songs',
        // Share Profile Page
        shareProfileHeader: 'SHARE YOUR PROFILE',
        shareProfileSubHeader: 'Let friends know where to find your public playlists.',
        shareProfile: 'Share',
        // General
        done: 'Done',
    },
    fr_CA: {
        // Setup Profile Page
        setupProfileHeader: 'Configurer le profil',
        setupProfileSubHeader: 'Pour partager vos listes de lecture, votre profil Amazon Music doit être public',
        setupProfileProfileName: 'Nom du profil',
        setupProfileNameDescription: 'Les modifications apportées à votre nom ou photo modifient également votre profil Amazon. ',
        setupProfileLearnMore: 'En savoir plus',
        setupProfileListeningHistoryTitle: 'Activité d’écoute musicale',
        setupProfileListeningHistoryDescription: 'Faites savoir à vos abonnés ce que vous écoutez',
        setupProfileContactDiscoveryTitle: 'Communiquer avec Détection',
        setupProfileContactDiscoveryDescription: 'Permettez aux gens qui ont votre numéro de téléphone de trouver votre profil sur tous les services d’Amazon. ',
        setupProfilePublicRadioTitle: 'Public',
        setupProfilePublicRadioDescription: 'Les gens peuvent voir votre nom, votre photo et vos listes de lecture publiques. Ils peuvent également suivre votre profil.',
        setupProfilePrivateRadioTitle: 'Privé',
        setupProfilePrivateRadioDescription: 'Les gens voient seulement votre nom et votre photo.',
        setupProfileSubmitButton: 'Continuer',
        setupProfileContactDiscoveryLearnMoreDescription: 'Cette configuration touche tous les services d’Amazon qui ont accès à vos contacts. En désactivant cette option, vous ne pourrez pas utiliser les fonctionnalités d’appel ou Drop In sur Alexa. Pour accorder une autorisation à des services individuels, accédez à Plus > Paramètres > Communication > Gérer les contacts > Numéro de cellulaire, dans l’application Alexa.',
        setupProfileLearnMoreGotIt: 'J’ai compris',
        setupProfileNameLearnMoreTitle: 'Profils Amazon',
        setupProfileNameLearnMoreDescription: 'Votre profil Amazon comprend des éléments tels que le nom, la photo de profil, les avis et les publications que les autres clients Amazon peuvent voir lorsqu\'ils font leurs achats. Pour modifier ce qui est public dans votre profil, vous pouvez modifier les paramètres de confidentialité.',
        // Make Playlists Public Page
        publicPlaylistHeader: 'Voulez-vous rendre vos listes de lecture publiques?',
        publicPlaylistSubHeader: 'Les abonnés les verront sur votre profil.',
        publicPlaylistMakePublic: 'RENDRE PUBLIC',
        publicPlaylistPublic: 'PUBLIC',
        publicPlaylistSkip: 'Sauter',
        publicPlaylistDurationMultipleHours: '{songCount} {songText}  •  {hours} H {minutes} min',
        publicPlaylistDurationOneHour: '{songCount} {songText}  •  {hours} H {minutes} min',
        publicPlaylistDuration: '{songCount} {songText}  •  {minutes} min {seconds} S',
        publicPlaylistNoSongs: 'Aucun titre',
        publicPlaylistOneSong: 'titre',
        publicPlaylistMultipleSongs: 'titres',
        // Share Profile Page
        shareProfileHeader: 'PARTAGER VOTRE PROFIL',
        shareProfileSubHeader: 'Indiquez à vos amis où trouver vos listes de lecture publiques.',
        shareProfile: 'Partager',
        // General
        done: 'Terminé',
    },
    es_MX: {
        // Setup Profile Page
        setupProfileHeader: 'Configurar perfil',
        setupProfileSubHeader: 'Para compartir tus playlists, tu perfil de Amazon Music debe ser público',
        setupProfileProfileName: 'Nombre de perfil',
        setupProfileNameDescription: 'Los cambios que hagas aquí en tu nombre o foto también se reflejarán en tu perfil de Amazon. ',
        setupProfileLearnMore: 'Más información',
        setupProfileListeningHistoryTitle: 'Actividad de escucha de música',
        setupProfileListeningHistoryDescription: 'Deja que tus seguidores vean lo que escuchaste',
        setupProfileContactDiscoveryTitle: 'Descubrir contactos',
        setupProfileContactDiscoveryDescription: 'Permite a las personas que tengan tu número de teléfono encontrar tu perfil en los servicios de Amazon. ',
        setupProfilePublicRadioTitle: 'Público',
        setupProfilePublicRadioDescription: 'La gente puede ver tu nombre, foto y playlists públicas. También puede seguir tu perfil.',
        setupProfilePrivateRadioTitle: 'Privado',
        setupProfilePrivateRadioDescription: 'La gente solo ve tu nombre y tu foto.',
        setupProfileSubmitButton: 'Continuar',
        setupProfileContactDiscoveryLearnMoreDescription: 'Esta configuración afecta a todos los servicios de Amazon que acceden a tus contactos. Si la desactivas, no podrás usar las funciones de llamada ni de Drop In en Alexa. Para otorgar permiso para servicios individuales, ve a Más > Configuración > Comunicación > Administrar contactos > Número de celular en la app de Alexa.',
        setupProfileLearnMoreGotIt: 'Entendido',
        setupProfileNameLearnMoreTitle: 'Perfiles de Amazon',
        setupProfileNameLearnMoreDescription: 'Tu perfil de Amazon incluye nombre, foto, reseñas y posts que otros clientes de Amazon pueden ver al comprar productos. Puedes modificar esto en ajustes de privacidad.',
        // Make Playlists Public Page
        publicPlaylistHeader: '¿Quieres hacer públicas tus playlists?',
        publicPlaylistSubHeader: 'Los seguidores las verán en tu perfil.',
        publicPlaylistMakePublic: 'HACER PÚBLICA',
        publicPlaylistPublic: 'PÚBLICA',
        publicPlaylistSkip: 'Omitir',
        publicPlaylistDurationMultipleHours: '{songCount} {songText}  •  {hours} h {minutes} min',
        publicPlaylistDurationOneHour: '{songCount} {songText}  •  {hours} h {minutes} min',
        publicPlaylistDuration: '{songCount} {songText}  •  {minutes} min {seconds} s',
        publicPlaylistNoSongs: 'No hay canciones',
        publicPlaylistOneSong: 'canción',
        publicPlaylistMultipleSongs: 'canciones',
        // Share Profile Page
        shareProfileHeader: 'COMPARTE TU PERFIL',
        shareProfileSubHeader: 'Deja que tus amigos sepan dónde encontrar tus playlists públicas.',
        shareProfile: 'Compartir',
        // General
        done: 'Listo',
    },
    es_CO: {
        // Setup Profile Page
        setupProfileHeader: 'Configurar perfil',
        setupProfileSubHeader: 'Para compartir tus playlists, tu perfil de Amazon Music debe ser público',
        setupProfileProfileName: 'Nombre de perfil',
        setupProfileNameDescription: 'Los cambios que hagas aquí en tu nombre o foto también se reflejarán en tu perfil de Amazon. ',
        setupProfileLearnMore: 'Más información',
        setupProfileListeningHistoryTitle: 'Actividad de escucha de música',
        setupProfileListeningHistoryDescription: 'Deja que tus seguidores vean lo que escuchaste',
        setupProfileContactDiscoveryTitle: 'Descubrir contactos',
        setupProfileContactDiscoveryDescription: 'Permite a las personas que tengan tu número de teléfono encontrar tu perfil en los servicios de Amazon. ',
        setupProfilePublicRadioTitle: 'Público',
        setupProfilePublicRadioDescription: 'La gente puede ver tu nombre, foto y playlists públicas. También puede seguir tu perfil.',
        setupProfilePrivateRadioTitle: 'Privado',
        setupProfilePrivateRadioDescription: 'La gente solo ve tu nombre y tu foto.',
        setupProfileSubmitButton: 'Continuar',
        setupProfileContactDiscoveryLearnMoreDescription: 'Esta configuración afecta a todos los servicios de Amazon que acceden a tus contactos. Si la desactivas, no podrás usar las funciones de llamada ni de Drop In en Alexa. Para otorgar permiso para servicios individuales, ve a Más > Configuración > Comunicación > Administrar contactos > Número de celular en la app de Alexa.',
        setupProfileLearnMoreGotIt: 'Entendido',
        setupProfileNameLearnMoreTitle: 'Perfiles de Amazon',
        setupProfileNameLearnMoreDescription: 'Tu perfil de Amazon incluye nombre, foto, reseñas y posts que otros clientes de Amazon pueden ver al comprar productos. Puedes modificar esto en ajustes de privacidad.',
        // Make Playlists Public Page
        publicPlaylistHeader: '¿Quieres hacer públicas tus playlists?',
        publicPlaylistSubHeader: 'Los seguidores las verán en tu perfil.',
        publicPlaylistMakePublic: 'HACER PÚBLICA',
        publicPlaylistPublic: 'PÚBLICA',
        publicPlaylistSkip: 'Omitir',
        publicPlaylistDurationMultipleHours: '{songCount} {songText}  •  {hours} h {minutes} min',
        publicPlaylistDurationOneHour: '{songCount} {songText}  •  {hours} h {minutes} min',
        publicPlaylistDuration: '{songCount} {songText}  •  {minutes} min {seconds} s',
        publicPlaylistNoSongs: 'No hay canciones',
        publicPlaylistOneSong: 'canción',
        publicPlaylistMultipleSongs: 'canciones',
        // Share Profile Page
        shareProfileHeader: 'COMPARTE TU PERFIL',
        shareProfileSubHeader: 'Deja que tus amigos sepan dónde encontrar tus playlists públicas.',
        shareProfile: 'Compartir',
        // General
        done: 'Listo',
    },
    es_CL: {
        // Setup Profile Page
        setupProfileHeader: 'Configurar perfil',
        setupProfileSubHeader: 'Para compartir tus playlists, tu perfil de Amazon Music debe ser público',
        setupProfileProfileName: 'Nombre de perfil',
        setupProfileNameDescription: 'Los cambios que hagas aquí en tu nombre o foto también se reflejarán en tu perfil de Amazon. ',
        setupProfileLearnMore: 'Más información',
        setupProfileListeningHistoryTitle: 'Actividad de escucha de música',
        setupProfileListeningHistoryDescription: 'Deja que tus seguidores vean lo que escuchaste',
        setupProfileContactDiscoveryTitle: 'Descubrir contactos',
        setupProfileContactDiscoveryDescription: 'Permite a las personas que tengan tu número de teléfono encontrar tu perfil en los servicios de Amazon. ',
        setupProfilePublicRadioTitle: 'Público',
        setupProfilePublicRadioDescription: 'La gente puede ver tu nombre, foto y playlists públicas. También puede seguir tu perfil.',
        setupProfilePrivateRadioTitle: 'Privado',
        setupProfilePrivateRadioDescription: 'La gente solo ve tu nombre y tu foto.',
        setupProfileSubmitButton: 'Continuar',
        setupProfileContactDiscoveryLearnMoreDescription: 'Esta configuración afecta a todos los servicios de Amazon que acceden a tus contactos. Si la desactivas, no podrás usar las funciones de llamada ni de Drop In en Alexa. Para otorgar permiso para servicios individuales, ve a Más > Configuración > Comunicación > Administrar contactos > Número de celular en la app de Alexa.',
        setupProfileLearnMoreGotIt: 'Entendido',
        setupProfileNameLearnMoreTitle: 'Perfiles de Amazon',
        setupProfileNameLearnMoreDescription: 'Tu perfil de Amazon incluye nombre, foto, reseñas y posts que otros clientes de Amazon pueden ver al comprar productos. Puedes modificar esto en ajustes de privacidad.',
        // Make Playlists Public Page
        publicPlaylistHeader: '¿Quieres hacer públicas tus playlists?',
        publicPlaylistSubHeader: 'Los seguidores las verán en tu perfil.',
        publicPlaylistMakePublic: 'HACER PÚBLICA',
        publicPlaylistPublic: 'PÚBLICA',
        publicPlaylistSkip: 'Omitir',
        publicPlaylistDurationMultipleHours: '{songCount} {songText}  •  {hours} h {minutes} min',
        publicPlaylistDurationOneHour: '{songCount} {songText}  •  {hours} h {minutes} min',
        publicPlaylistDuration: '{songCount} {songText}  •  {minutes} min {seconds} s',
        publicPlaylistNoSongs: 'No hay canciones',
        publicPlaylistOneSong: 'canción',
        publicPlaylistMultipleSongs: 'canciones',
        // Share Profile Page
        shareProfileHeader: 'COMPARTE TU PERFIL',
        shareProfileSubHeader: 'Deja que tus amigos sepan dónde encontrar tus playlists públicas.',
        shareProfile: 'Compartir',
        // General
        done: 'Listo',
    },
    pt_BR: {
        // Setup Profile Page
        setupProfileHeader: 'Configurar perfil',
        setupProfileSubHeader: 'Para compartilhar suas playlists, o perfil do Amazon Music deve ser público',
        setupProfileProfileName: 'Nome do Perfil',
        setupProfileNameDescription: 'As alterações no seu nome ou foto aqui também alteram seu perfil da Amazon. ',
        setupProfileLearnMore: 'Saiba mais',
        setupProfileListeningHistoryTitle: 'Atividade de músicas ouvidas',
        setupProfileListeningHistoryDescription: 'Permita que os seguidores vejam o que você está ouvindo',
        setupProfileContactDiscoveryTitle: 'Descoberta de contatos',
        setupProfileContactDiscoveryDescription: 'Permita que as pessoas que têm seu número de telefone encontrem seu perfil nos serviços da Amazon. ',
        setupProfilePublicRadioTitle: 'Público',
        setupProfilePublicRadioDescription: 'As pessoas podem ver seu nome, sua foto e suas playlists públicas. Elas também podem seguir seu perfil.',
        setupProfilePrivateRadioTitle: 'Privado',
        setupProfilePrivateRadioDescription: 'As pessoas podem ver apenas seu nome e foto.',
        setupProfileSubmitButton: 'Continuar',
        setupProfileContactDiscoveryLearnMoreDescription: 'Esta configuração afeta todos os serviços da Amazon que acessam seus contatos. Se você desativar, não será possível usar os recursos de chamada ou de Drop In na Alexa. Para conceder permissão a serviços individuais, acesse Mais>Configurações>Comunicação>Gerenciar contatos>Número de celular no app da Alexa.',
        setupProfileLearnMoreGotIt: 'Entendi',
        setupProfileNameLearnMoreTitle: 'Perfis da Amazon',
        setupProfileNameLearnMoreDescription: 'Seu perfil da Amazon inclui coisas como nome, foto, resenhas e postagens que outros clientes da Amazon podem ver enquanto compram. Para alterar o que è público em seu perfil, você pode editar as configurações de privacidade.',
        // Make Playlists Public Page
        publicPlaylistHeader: 'Tornar suas playlists públicas?',
        publicPlaylistSubHeader: 'Os seguidores vão vê-las no seu perfil.',
        publicPlaylistMakePublic: 'TORNAR PÚBLICO',
        publicPlaylistPublic: 'PÚBLICO',
        publicPlaylistSkip: 'Pular',
        publicPlaylistDurationMultipleHours: '{songCount} {songText}  •  {hours} hs {minutes} min',
        publicPlaylistDurationOneHour: '{songCount} {songText}  •  {hours} h {minutes} min',
        publicPlaylistDuration: '{songCount} {songText}  •  {minutes} min {seconds} s',
        publicPlaylistNoSongs: 'Nenhuma música',
        publicPlaylistOneSong: 'música',
        publicPlaylistMultipleSongs: 'músicas',
        // Share Profile Page
        shareProfileHeader: 'COMPARTILHE SEU PERFIL',
        shareProfileSubHeader: 'Permite que seus amigos saibam onde encontrar suas playlists públicas.',
        shareProfile: 'Compartilhar',
        // General
        done: 'Concluído',
    },
    en_GB: {
        // Setup Profile Page
        setupProfileHeader: 'Set up profile',
        setupProfileSubHeader: 'To share your playlists, your Amazon Music profile must be public',
        setupProfileProfileName: 'Profile Name',
        setupProfileNameDescription: 'Changes to your name or photo here will also change your Amazon profile. ',
        setupProfileLearnMore: 'Learn more',
        setupProfileListeningHistoryTitle: 'Music listening activity',
        setupProfileListeningHistoryDescription: 'Let followers see what you\'ve been listening to',
        setupProfileContactDiscoveryTitle: 'Contact discovery',
        setupProfileContactDiscoveryDescription: 'Let people with your phone number find your profile across Amazon services. ',
        setupProfilePublicRadioTitle: 'Public',
        setupProfilePublicRadioDescription: 'People can see your name, photo and public playlists. They can also follow your profile.',
        setupProfilePrivateRadioTitle: 'Private',
        setupProfilePrivateRadioDescription: 'People can only see your name and photo.',
        setupProfileSubmitButton: 'Continue',
        setupProfileContactDiscoveryLearnMoreDescription: 'This setting affects all Amazon services that access your contacts. If you turn this off, you won’t be able to use the calling or Drop In features on Alexa. To grant permission to individual services, go to More>Settings>Communication>Manage Contacts>Mobile Number in the Alexa app.',
        setupProfileLearnMoreGotIt: 'Got it',
        setupProfileNameLearnMoreTitle: 'Amazon Profiles',
        setupProfileNameLearnMoreDescription: 'Your Amazon Profile includes things like name, picture, reviews, and posts that other Amazon customers can see as they shop. To change what\'s public in your profile, you can edit privacy settings.',
        // Make Playlists Public Page
        publicPlaylistHeader: 'Make your playlists public?',
        publicPlaylistSubHeader: 'Followers will see them on your profile.',
        publicPlaylistMakePublic: 'MAKE PUBLIC',
        publicPlaylistPublic: 'PUBLIC',
        publicPlaylistSkip: 'Skip',
        publicPlaylistDurationMultipleHours: '{songCount} {songText}  •  {hours} hrs {minutes} min',
        publicPlaylistDurationOneHour: '{songCount} {songText}  •  {hours} hr {minutes} min',
        publicPlaylistDuration: '{songCount} {songText}  •  {minutes} min {seconds} sec',
        publicPlaylistNoSongs: 'No songs',
        publicPlaylistOneSong: 'song',
        publicPlaylistMultipleSongs: 'songs',
        // Share Profile Page
        shareProfileHeader: 'SHARE YOUR PROFILE',
        shareProfileSubHeader: 'Let friends know where to find your public playlists.',
        shareProfile: 'Share',
        // General
        done: 'Done',
    },
    de_DE: {
        // Setup Profile Page
        setupProfileHeader: 'Profil einrichten',
        setupProfileSubHeader: 'Um deine Playlists zu teilen, muss dein Amazon Music-Profil öffentlich sein.',
        setupProfileProfileName: 'Profilname',
        setupProfileNameDescription: 'Änderungen an deinem Namen oder Foto hier ändern auch dein Amazon-Profil. ',
        setupProfileLearnMore: 'Mehr erfahren',
        setupProfileListeningHistoryTitle: 'Musikhöraktivität',
        setupProfileListeningHistoryDescription: 'Lass Follower wissen, was du gehört hast.',
        setupProfileContactDiscoveryTitle: 'Kontaktermittlung',
        setupProfileContactDiscoveryDescription: 'Lass Personen mit deiner Mobiltelefonnummer dein Profil über Amazon-Services finden. ',
        setupProfilePublicRadioTitle: 'Öffentlich',
        setupProfilePublicRadioDescription: 'Personen können deinen Namen, dein Foto und deine öffentlichen Playlists sehen. Sie können auch deinem Profil folgen.',
        setupProfilePrivateRadioTitle: 'Privat',
        setupProfilePrivateRadioDescription: 'Personen sehen nur deinen Namen und dein Foto.',
        setupProfileSubmitButton: 'Weiter',
        setupProfileContactDiscoveryLearnMoreDescription: 'Diese Einstellung betrifft alle Amazon-Services, die auf deine Kontakte zugreifen. Wenn du die Einstellung deaktivierst, kannst du die Anruf- und die Drop In-Funktion von Alexa nicht nutzen. Wenn du einzelnen Services Berechtigungen erteilen möchtest, rufe in der Alexa-App Mehr>Einstellungen>Kommunikation>Kontakte verwalten>Mobiltelefonnummer auf.',
        setupProfileLearnMoreGotIt: 'Verstanden',
        setupProfileNameLearnMoreTitle: 'Profile bei Amazon',
        setupProfileNameLearnMoreDescription: 'Ihr Amazon-Profil enthält Namen, Bild, Rezensionen und Posts, die andere Amazon-Kunden beim Einkaufen sehen können. Um zu ändern, was in Ihrem Profil öffentlich ist, bearbeiten Sie die Datenschutzeinstellungen.',
        // Make Playlists Public Page
        publicPlaylistHeader: 'Deine Playlists veröffentlichen?',
        publicPlaylistSubHeader: 'Follower sehen sie in deinem Profil.',
        publicPlaylistMakePublic: 'VERÖFFENTLICHEN',
        publicPlaylistPublic: 'ÖFFENTLICH',
        publicPlaylistSkip: 'Überspringen',
        publicPlaylistDurationMultipleHours: '{songCount} {songText}  •  {hours} STD {minutes} MIN',
        publicPlaylistDurationOneHour: '{songCount} {songText}  •  {hours} STD {minutes} MIN',
        publicPlaylistDuration: '{songCount} {songText}  •  {minutes} MIN {seconds} SEC',
        publicPlaylistNoSongs: 'Keine Songs',
        publicPlaylistOneSong: 'Song',
        publicPlaylistMultipleSongs: 'Songs',
        // Share Profile Page
        shareProfileHeader: 'DEIN PROFIL TEILEN',
        shareProfileSubHeader: 'Lass Freunde wissen, wo sie deine öffentlichen Playlists finden.',
        shareProfile: 'Teilen',
        // General
        done: 'Fertig',
    },
    fr_FR: {
        // Setup Profile Page
        setupProfileHeader: 'Configurez votre profil',
        setupProfileSubHeader: 'Pour pouvoir partager vos playlists, votre profil Amazon Music doit être public',
        setupProfileProfileName: 'Nom du profil',
        setupProfileNameDescription: 'Les modifications apportées ici à votre nom ou photo seront reflétées dans votre profil Amazon. ',
        setupProfileLearnMore: 'En savoir plus',
        setupProfileListeningHistoryTitle: 'Activité d’écoute musicale',
        setupProfileListeningHistoryDescription: 'Vos followers peuvent voir ce que vous avez écouté dernièrement.',
        setupProfileContactDiscoveryTitle: 'Détection de contact',
        setupProfileContactDiscoveryDescription: 'Les personnes qui ont votre numéro de téléphone peuvent trouver votre profil sur l’ensemble des services Amazon. ',
        setupProfilePublicRadioTitle: 'Public',
        setupProfilePublicRadioDescription: 'Les utilisateurs peuvent voir votre nom, votre photo et vos playlists publiques. Ils peuvent également suivre votre profil.',
        setupProfilePrivateRadioTitle: 'Privé',
        setupProfilePrivateRadioDescription: 'Les utilisateurs peuvent uniquement voir votre nom et votre photo.',
        setupProfileSubmitButton: 'Continuer',
        setupProfileContactDiscoveryLearnMoreDescription: 'Ce paramètre affecte tous les services Amazon qui ont accès à vos contacts. Si vous le désactivez, vous ne pourrez plus utiliser les fonctionnalités Drop In ou Appels sur Alexa. Pour autoriser l’accès aux services individuels dans l’application Alexa, accédez à Plus > Paramètres > Communication > Gérer les contacts > Numéro de téléphone mobile.',
        setupProfileLearnMoreGotIt: 'J’ai compris',
        setupProfileNameLearnMoreTitle: 'Profils Amazon',
        setupProfileNameLearnMoreDescription: 'Votre profil Amazon comprend des éléments tels que le nom, la photo de profil, les avis et les publications que les autres clients Amazon peuvent voir lorsqu\'ils font leurs achats. Pour modifier ce qui est public dans votre profil, vous pouvez modifier les paramètres de confidentialité.',
        // Make Playlists Public Page
        publicPlaylistHeader: 'Rendre vos playlists publiques ?',
        publicPlaylistSubHeader: 'Vos followers pourront les voir sur votre profil.',
        publicPlaylistMakePublic: 'RENDRE PUBLIC',
        publicPlaylistPublic: 'PUBLIC',
        publicPlaylistSkip: 'Ignorer',
        publicPlaylistDurationMultipleHours: '{songCount} {songText}  •  {hours} h {minutes} min',
        publicPlaylistDurationOneHour: '{songCount} {songText}  •  {hours} h {minutes} min',
        publicPlaylistDuration: '{songCount} {songText}  •  {minutes} min {seconds} s',
        publicPlaylistNoSongs: 'Aucun titre',
        publicPlaylistOneSong: 'titre',
        publicPlaylistMultipleSongs: 'titres',
        // Share Profile Page
        shareProfileHeader: 'PARTAGEZ VOTRE PROFIL',
        shareProfileSubHeader: 'Indiquez à vos amis où trouver vos playlists publiques.',
        shareProfile: 'Partager',
        // General
        done: 'Terminé',
    },
    it_IT: {
        // Setup Profile Page
        setupProfileHeader: 'Imposta profilo',
        setupProfileSubHeader: 'Per condividere le playlist, il tuo profilo Amazon Music deve essere pubblico',
        setupProfileProfileName: 'Nome del Profilo',
        setupProfileNameDescription: 'Le modifiche al tuo nome o alla foto si riflettono anche sul tuo profilo Amazon. ',
        setupProfileLearnMore: 'Scopri di più',
        setupProfileListeningHistoryTitle: 'Attività di ascolto della musica',
        setupProfileListeningHistoryDescription: 'Fai vedere ai tuoi follower cosa ascolti',
        setupProfileContactDiscoveryTitle: 'Ricerca tramite recapito',
        setupProfileContactDiscoveryDescription: 'Consenti agli utenti in possesso del tuo numero di telefono di trovare il tuo profilo sui servizi Amazon. ',
        setupProfilePublicRadioTitle: 'Pubblico',
        setupProfilePublicRadioDescription: 'Gli utenti possono visualizzare il tuo nome, la tua foto e le playlist pubbliche. Inoltre, possono seguire il tuo profilo.',
        setupProfilePrivateRadioTitle: 'Privato',
        setupProfilePrivateRadioDescription: 'Gli utenti visualizzano solo il tuo nome e la tua foto.',
        setupProfileSubmitButton: 'Continua',
        setupProfileContactDiscoveryLearnMoreDescription: 'Questa impostazione interessa i servizi Amazon che hanno accesso ai tuoi contatti. Se la disattivi, non potrai usare le funzionalità di chiamata o Drop In su Alexa. Per concedere l\'autorizzazione ai servizi individuali, vai su Altro > Impostazioni > Comunicazione > Gestisci contatti > Numero di cellulare nell\'app Alexa.',
        setupProfileLearnMoreGotIt: 'Capito',
        setupProfileNameLearnMoreTitle: 'Profili Amazon',
        setupProfileNameLearnMoreDescription: 'Il tuo Profilo di Amazon include nome, foto profilo, recensioni e commenti che altri clienti Amazon possono vedere quando effettuano i loro acquisti. Per modificare le informazioni pubbliche sul tuo profilo, puoi aggiornare le impostazioni della privacy.',
        // Make Playlists Public Page
        publicPlaylistHeader: 'Vuoi rendere pubbliche le tue playlist?',
        publicPlaylistSubHeader: 'I follower le vedranno sul tuo profilo.',
        publicPlaylistMakePublic: 'RENDI PUBBLICA',
        publicPlaylistPublic: 'PUBBLICA',
        publicPlaylistSkip: 'Salta',
        publicPlaylistDurationMultipleHours: '{songCount} {songText}  •  {hours} ore {minutes} min',
        publicPlaylistDurationOneHour: '{songCount} {songText}  •  {hours} ora {minutes} min',
        publicPlaylistDuration: '{songCount} {songText}  •  {minutes} min {seconds} s',
        publicPlaylistNoSongs: 'Nessun brano',
        publicPlaylistOneSong: 'brano',
        publicPlaylistMultipleSongs: 'brani',
        // Share Profile Page
        shareProfileHeader: 'CONDIVIDI IL TUO PROFILO',
        shareProfileSubHeader: 'Consenti ai tuoi amici di trovare le tue playlist pubbliche.',
        shareProfile: 'Condividi',
        // General
        done: 'Fatto',
    },
    es_ES: {
        // Setup Profile Page
        setupProfileHeader: 'Configurar perfil',
        setupProfileSubHeader: 'Para compartir tus playlists, tu perfil de Amazon Music debe ser público',
        setupProfileProfileName: 'Nombre de perfil',
        setupProfileNameDescription: 'Los cambios que realices aquí en tu nombre o foto también se reflejarán en tu perfil de Amazon. ',
        setupProfileLearnMore: 'Más información',
        setupProfileListeningHistoryTitle: 'Actividad de escucha de música',
        setupProfileListeningHistoryDescription: 'Deja que tus seguidores vean lo que has estado escuchando',
        setupProfileContactDiscoveryTitle: 'Descubrir contactos',
        setupProfileContactDiscoveryDescription: 'Permite a las personas que tengan tu número de teléfono encontrar tu perfil en los servicios de Amazon. ',
        setupProfilePublicRadioTitle: 'Público',
        setupProfilePublicRadioDescription: 'La gente puede ver tu nombre, tu foto y tus playlists públicas. También puede seguir tu perfil.',
        setupProfilePrivateRadioTitle: 'Privado',
        setupProfilePrivateRadioDescription: 'La gente solo ve tu nombre y tu foto.',
        setupProfileSubmitButton: 'Continuar',
        setupProfileContactDiscoveryLearnMoreDescription: 'Esta configuración afecta a todos los servicios de Amazon que acceden a tus contactos. Si la desactivas, no podrás usar las funciones de llamada ni de Drop In de Alexa. Para otorgar permiso para servicios individuales, ve a Más > Configuración > Comunicación > Gestionar contactos > Número de móvil en la app de Alexa.',
        setupProfileLearnMoreGotIt: 'Entendido',
        setupProfileNameLearnMoreTitle: 'Perfiles de Amazon',
        setupProfileNameLearnMoreDescription: 'Tu perfil de Amazon incluye nombre, foto, reseñas y posts que otros clientes de Amazon pueden ver al comprar productos. Puedes modificar esto en ajustes de privacidad.',
        // Make Playlists Public Page
        publicPlaylistHeader: '¿Quieres hacer públicas tus playlists?',
        publicPlaylistSubHeader: 'Los seguidores las verán en tu perfil.',
        publicPlaylistMakePublic: 'HACER PÚBLICA',
        publicPlaylistPublic: 'PÚBLICA',
        publicPlaylistSkip: 'Saltar',
        publicPlaylistDurationMultipleHours: '{songCount} {songText}  •  {hours} h {minutes} min',
        publicPlaylistDurationOneHour: '{songCount} {songText}  •  {hours} h {minutes} min',
        publicPlaylistDuration: '{songCount} {songText}  •  {minutes} min {seconds} s',
        publicPlaylistNoSongs: 'No hay canciones',
        publicPlaylistOneSong: 'canción',
        publicPlaylistMultipleSongs: 'canciones',
        // Share Profile Page
        shareProfileHeader: 'COMPARTE TU PERFIL',
        shareProfileSubHeader: 'Deja que tus amigos sepan dónde encontrar tus playlists públicas.',
        shareProfile: 'Compartir',
        // General
        done: 'Hecho',
    },
    ja_JP: {
        // Setup Profile Page
        setupProfileHeader: 'プロフィールを設定',
        setupProfileSubHeader: 'プレイリストをシェアするには、Amazon Musicのプロフィールを公開する必要があります',
        setupProfileProfileName: 'プロフィール名',
        setupProfileNameDescription: 'ここで名前や写真を変更すると、Amazonプロフィールも変更されます。',
        setupProfileLearnMore: '詳しく見る',
        setupProfileListeningHistoryTitle: '音楽の再生アクティビティ',
        setupProfileListeningHistoryDescription: 'あなたが何を聴いているかをフォロワーが見ることができます。',
        setupProfileContactDiscoveryTitle: '連絡先検出',
        setupProfileContactDiscoveryDescription: '他のユーザーは、Amazonサービス全体であなたの電話番号からあなたのプロフィールを見つけることができます。',
        setupProfilePublicRadioTitle: '公開',
        setupProfilePublicRadioDescription: '他のユーザーは、あなたの名前、写真、公開プレイリストを閲覧できます。また、あなたのプロフィールをフォローすることもできます。',
        setupProfilePrivateRadioTitle: '限定公開',
        setupProfilePrivateRadioDescription: '他のユーザーは、あなたの名前と写真のみを見ることができます。',
        setupProfileSubmitButton: '続行',
        setupProfileContactDiscoveryLearnMoreDescription: 'この設定は、あなたの連絡先にアクセスするすべてのAmazonサービスに影響します。これをオフにすると、Alexaの通話機能や呼びかけ機能が使えなくなります。個々のサービスに許可を与えるには、Alexaアプリで「その他」>「設定」>「コミュニケーション」>「連絡先の管理」>「携帯電話番号」に進みます。',
        setupProfileLearnMoreGotIt: 'OK',
        setupProfileNameLearnMoreTitle: 'Amazonのプロフィール',
        setupProfileNameLearnMoreDescription: 'Amazonのプロフィールは、プロフィール名や写真のほか、カスタマーレビューなどAmazonをご利用のお客様がお買い物する際に閲覧できる情報が含まれます。公開情報はプライバシー設定で変更できます。',
        // Make Playlists Public Page
        publicPlaylistHeader: 'プレイリストを公開しますか?',
        publicPlaylistSubHeader: 'フォロワーはあなたのプロフィールで見ることができます。',
        publicPlaylistMakePublic: '公開する',
        publicPlaylistPublic: '公開',
        publicPlaylistSkip: 'スキップ',
        publicPlaylistDurationMultipleHours: '{songCount} {songText}  •  {hours} 時間 {minutes} 分',
        publicPlaylistDurationOneHour: '{songCount} {songText}  •  {hours} 時間 {minutes} 分',
        publicPlaylistDuration: '{songCount} {songText}  •  {minutes} 分 {seconds} 秒',
        publicPlaylistNoSongs: '0 曲',
        publicPlaylistOneSong: '曲',
        publicPlaylistMultipleSongs: '曲',
        // Share Profile Page
        shareProfileHeader: 'プロフィールをシェアする',
        shareProfileSubHeader: '公開プレイリストが見つかる場所を友達に教えましょう。',
        shareProfile: 'シェアする',
        // General
        done: '完了',
    },
    en_AU: {
        // Setup Profile Page
        setupProfileHeader: 'Set up profile',
        setupProfileSubHeader: 'To share your playlists, your Amazon Music profile must be public',
        setupProfileProfileName: 'Profile Name',
        setupProfileNameDescription: 'Changes to your name or photo here will also change your Amazon profile. ',
        setupProfileLearnMore: 'Learn more',
        setupProfileListeningHistoryTitle: 'Music listening activity',
        setupProfileListeningHistoryDescription: 'Let followers see what you\'ve been listening to',
        setupProfileContactDiscoveryTitle: 'Contact discovery',
        setupProfileContactDiscoveryDescription: 'Let people with your phone number find your profile across Amazon services. ',
        setupProfilePublicRadioTitle: 'Public',
        setupProfilePublicRadioDescription: 'People can see your name, photo and public playlists. They can also follow your profile.',
        setupProfilePrivateRadioTitle: 'Private',
        setupProfilePrivateRadioDescription: 'People can only see your name and photo.',
        setupProfileSubmitButton: 'Continue',
        setupProfileContactDiscoveryLearnMoreDescription: 'This setting affects all Amazon services that access your contacts. If you turn this off, you won’t be able to use the calling or Drop In features on Alexa. To grant permission to individual services, go to More>Settings>Communication>Manage Contacts>Mobile Number in the Alexa app.',
        setupProfileLearnMoreGotIt: 'Got it',
        setupProfileNameLearnMoreTitle: 'Amazon Profiles',
        setupProfileNameLearnMoreDescription: 'Your Amazon Profile includes things like name, picture, reviews, and posts that other Amazon customers can see as they shop. To change what\'s public in your profile, you can edit privacy settings.',
        // Make Playlists Public Page
        publicPlaylistHeader: 'Make your playlists public?',
        publicPlaylistSubHeader: 'Followers will see them on your profile.',
        publicPlaylistMakePublic: 'MAKE PUBLIC',
        publicPlaylistPublic: 'PUBLIC',
        publicPlaylistSkip: 'Skip',
        publicPlaylistDurationMultipleHours: '{songCount} {songText}  •  {hours} hrs {minutes} min',
        publicPlaylistDurationOneHour: '{songCount} {songText}  •  {hours} hr {minutes} min',
        publicPlaylistDuration: '{songCount} {songText}  •  {minutes} min {seconds} sec',
        publicPlaylistNoSongs: 'No songs',
        publicPlaylistOneSong: 'song',
        publicPlaylistMultipleSongs: 'songs',
        // Share Profile Page
        shareProfileHeader: 'SHARE YOUR PROFILE',
        shareProfileSubHeader: 'Let friends know where to find your public playlists.',
        shareProfile: 'Share',
        // General
        done: 'Done',
    },
    en_IN: {
        // Setup Profile Page
        setupProfileHeader: 'Set up profile',
        setupProfileSubHeader: 'To share your playlists, your Amazon Music profile must be public',
        setupProfileProfileName: 'Profile Name',
        setupProfileNameDescription: 'Changes to your name or photo here will also change your Amazon profile. ',
        setupProfileLearnMore: 'Learn more',
        setupProfileListeningHistoryTitle: 'Music listening activity',
        setupProfileListeningHistoryDescription: 'Let followers see what you have been listening to and when',
        setupProfileContactDiscoveryTitle: 'Contact discovery',
        setupProfileContactDiscoveryDescription: 'Let people with your phone number find your profile across Amazon services. ',
        setupProfilePublicRadioTitle: 'Public',
        setupProfilePublicRadioDescription: 'People can see your name, photo and public playlists. They can also follow your profile.',
        setupProfilePrivateRadioTitle: 'Private',
        setupProfilePrivateRadioDescription: 'People can only see your name and photo.',
        setupProfileSubmitButton: 'Continue',
        setupProfileContactDiscoveryLearnMoreDescription: 'This setting affects all Amazon services that access your contacts. If you turn this off, you will not be able to use the calling or Drop In features on Alexa. To grant permission to individual services, go to More>Settings>Communication>Manage Contacts>Mobile Number in the Alexa app.',
        setupProfileLearnMoreGotIt: 'Got it',
        setupProfileNameLearnMoreTitle: 'Amazon Profiles',
        setupProfileNameLearnMoreDescription: 'Your Amazon Profile includes things like name, picture, reviews, and posts that other Amazon customers can see as they shop. To change what\'s public in your profile, you can edit privacy settings.',
        // Make Playlists Public Page
        publicPlaylistHeader: 'Make your playlists public?',
        publicPlaylistSubHeader: 'Followers will see them on your profile.',
        publicPlaylistMakePublic: 'MAKE PUBLIC',
        publicPlaylistPublic: 'PUBLIC',
        publicPlaylistSkip: 'Skip',
        publicPlaylistDurationMultipleHours: '{songCount} {songText}  •  {hours} hrs {minutes} min',
        publicPlaylistDurationOneHour: '{songCount} {songText}  •  {hours} hr {minutes} min',
        publicPlaylistDuration: '{songCount} {songText}  •  {minutes} min {seconds} sec',
        publicPlaylistNoSongs: 'No songs',
        publicPlaylistOneSong: 'song',
        publicPlaylistMultipleSongs: 'songs',
        // Share Profile Page
        shareProfileHeader: 'SHARE YOUR PROFILE',
        shareProfileSubHeader: 'Let friends know where to find your public playlists.',
        shareProfile: 'Share',
        // General
        done: 'Done',
    },
    hi_IN: {
        // Setup Profile Page
        setupProfileHeader: 'प्रोफ़ाइल सेट अप करें',
        setupProfileSubHeader: 'प्लेलिस्ट शेयर करने के लिए, Amazon Music की प्रोफ़ाइल सार्वजनिक होनी चाहिए',
        setupProfileProfileName: 'प्रोफ़ाइल नाम',
        setupProfileNameDescription: 'यहां अपना नाम और फ़ोटो बदलने पर, आपकी Amazon प्रोफ़ाइल में भी बदलाव दिखते हैं. ',
        setupProfileLearnMore: 'ज़्यादा जानें',
        setupProfileListeningHistoryTitle: 'कौनसा संगीत सुना जा रहा है',
        setupProfileListeningHistoryDescription: 'फ़ॉलोअर को देखने दें कि कब क्या सुना जा रहा है',
        setupProfileContactDiscoveryTitle: 'फ़ोन नंबर से ढूंढना',
        setupProfileContactDiscoveryDescription: 'जिन लोगों के पास आपका फ़ोन नंबर है उन्हें Amazon की सभी सेवाओं पर आपकी प्रोफ़ाइल ढूंढने दें. ',
        setupProfilePublicRadioTitle: 'सार्वजनिक',
        setupProfilePublicRadioDescription: 'लोग आपका नाम, फ़ोटो और सार्वजनिक प्लेलिस्ट देख सकते हैं. वे आपकी प्रोफ़ाइल भी फ़ॉलो कर सकते हैं.',
        setupProfilePrivateRadioTitle: 'निजी',
        setupProfilePrivateRadioDescription: 'लोग सिर्फ़ आपका नाम और फ़ोटो देख सकते हैं. ',
        setupProfileSubmitButton: 'जारी रखें',
        setupProfileContactDiscoveryLearnMoreDescription: 'इस सेटिंग से आपके फ़ोन नंबर से ऐक्सेस की जाने वाली Amazon की सभी सेवाओं पर असर पड़ता है. अगर इसे बंद किया जाता है, तो Alexa पर कॉलिंग या Drop In फ़ीचर का इस्तेमाल नहीं किया जा सकेगा. किसी खास सेवाओं को अनुमति देने के लिए, Alexa ऐप में More>Settings>Communication>Manage Contacts>Mobile Number पर जाएं.',
        setupProfileLearnMoreGotIt: 'समझ आ गया',
        setupProfileNameLearnMoreTitle: 'Amazon प्रोफ़ाइल',
        setupProfileNameLearnMoreDescription: 'आपकी Amazon प्रोफ़ाइल में नाम, तस्वीर, समीक्षाएं और पोस्ट जैसी चीज़ें हैं, जिन्हें खरीदी करते समय Amazon के ग्राहक देख सकते हैं. आपकी प्रोफ़ाइल में जो कुछ भी सार्वजनिक है, उसे बदलने के लिए आप निजता सेटिंग बदल सकते हैं.',
        // Make Playlists Public Page
        publicPlaylistHeader: 'क्या आपको प्लेलिस्ट सार्वजनिक करनी हैं?',
        publicPlaylistSubHeader: 'फ़ॉलोअर आपकी प्रोफ़ाइल पर उन्हें देख पाएंगे.',
        publicPlaylistMakePublic: 'सार्वजनिक करें',
        publicPlaylistPublic: 'सार्वजनिक',
        publicPlaylistSkip: 'स्किप करें',
        publicPlaylistDurationMultipleHours: '{songCount} {songText}  •  {hours} घंटे {minutes} मिनट',
        publicPlaylistDurationOneHour: '{songCount} {songText}  •  {hours} घंटा {minutes} mमिनट',
        publicPlaylistDuration: '{songCount} {songText}  •  {minutes} मिनट {seconds} sसेकंड',
        publicPlaylistNoSongs: 'कोई गाना नहीं मिला',
        publicPlaylistOneSong: 'गाना',
        publicPlaylistMultipleSongs: 'गाने',
        // Share Profile Page
        shareProfileHeader: 'अपनी प्रोफ़ाइल शेयर करें',
        shareProfileSubHeader: 'दोस्तों को बताएं कि आपकी सार्वजनिक प्लेलिस्ट उन्हें कहां मिलेगी.',
        shareProfile: 'शेयर करें',
        // General
        done: 'हो गया',
    },
};

export function getStrings(locale: string) {
    return strings[locale] || strings.en_US;
}
