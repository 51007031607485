import { unstable_HistoryRouter as HistoryRouter, Routes, Route } from 'react-router-dom';
import { render } from 'preact';
// Recommended to not add history as direct dependency to avoid version conflicts breaking the singleton behavior
// https://reactrouter.com/docs/en/v6/routers/history-router
import history from 'history/browser'; // eslint-disable-line import/no-extraneous-dependencies
import HelloWorld from './views/HelloWorld';
import AppInterfaceTestPage from './views/AppInterfaceTestPage';
import FireFlyInterfaceTestPage from './views/FireFlyInterfaceTestPage';
import ProfileSetupPage from './views/ProfileSetupPage';
import ShareProfilePage from './views/ShareProfilePage';
import PlaylistVisibilityPage from './views/PlaylistVisibilityPage';
import { PATHS } from './util';
import './style/App.scss';

const bodySelector: HTMLElement = document.getElementById('root') || document.body;
const app = (
    <music-app>
        <HistoryRouter history={history}>
            <Routes>
                <Route path={PATHS.SETUP} element={<ProfileSetupPage />} />
                <Route path={PATHS.SHARE_PROFILE} element={<ShareProfilePage />} />
                <Route path={PATHS.PLAYLIST_VISIBILITY} element={<PlaylistVisibilityPage />} />
                <Route path="test" element={<AppInterfaceTestPage />} />
                <Route path="testFirefly" element={<FireFlyInterfaceTestPage />} />
                <Route path="*" element={<HelloWorld />} />
            </Routes>
        </HistoryRouter>
    </music-app>
);

render(
    app,
    bodySelector,
);
