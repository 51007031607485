// Types translated from iOS interface: https://tiny.amazon.com/1d2plqj96/codeamazpackContblobmainCont
export enum ContactSourceType {
    whitelistedContact = 'whitelistedContact',
    manuallyAddedContact = 'manuallyAddedContact',
    syncedContact = 'syncedContact',
    voiceAddedContact = 'voiceAddedContact',
    autoAddedContact = 'autoAddedContact',
    bulkImportedContact = 'bulkImportedContact',
    skypeContact = 'skypeContact',
    commsGroup = 'commsGroup',
    mergedContact = 'mergedContact',
    mutuallyAddedContact = 'mutuallyAddedContact',
    BTHubContact = 'BTHubContact',
    unsetContact = '',
    profileContact = 'profileContact',
    amazonPhotos = 'amazonPhotos',
    amazonMusic = 'amazonMusic',
}
