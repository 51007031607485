import {
    GetAccountResponse,
    GetContactsResponse,
    GetCustomerInfoResponse,
    GetPhoneNumberVerificationURLResponse,
    GetPreferenceForOwnerResponse,
    NextStepEnum,
    PhoneNumber,
    PreferenceKey,
    PreferenceTarget,
    PreferenceValue,
} from '../types';

// Private Types
// Defined locally as these shouldn't be publically accessed. iOS and Android responses vary significantly, and
// we only care about the current account, so we simplify the GetAccountResponse before returning
interface iOSAccount {
    actorId: string
    mapDirectedDefaultActorId: never
    signedInUser: boolean
    phoneNumber?: PhoneNumber
}

interface AndroidAccount {
    actorId: never
    mapDirectedDefaultActorId: string
    signedInUser: boolean
    phoneNumber?: PhoneNumber
}

interface CustomerIdentity {
    accounts: iOSAccount[]
}

interface GetAccountiOSResponse {
    customerIdentity: CustomerIdentity
    accounts: never
}

interface GetAccountAndroidResponse {
    accounts: AndroidAccount[]
    customerIdentity: never
}

type GetAccountInternalResponse = GetAccountiOSResponse | GetAccountAndroidResponse;

interface GetPhoneNumberVerificationURLAndroidResponse {
    nextStep?: NextStepEnum
    redirectURL: never
    redirectUrl: string
}
interface GetPhoneNumberVerificationURLiOSResponse {
    nextStep?: NextStepEnum
    redirectURL: string
    redirectUrl: never
}

type GetPhoneNumberVerificationURLInternalResponse =
    GetPhoneNumberVerificationURLAndroidResponse | GetPhoneNumberVerificationURLiOSResponse;

// Implementation

window.amzn = window.amzn || {
    _getAccount: [],
    _getContacts: [],
    _getCustomerInfo: [],
    _getPhoneNumberVerificationURL: [],
    _getPreferenceForOwner: [],
    _openOSAppSettings: [],
    _requestOSContactsPermissions: [],
    _setPreferenceForOwner: [],
    _shareProfile: [],
    _startContactsUpSync: [],
};

/**
 * Internal function that handles calling the native app and setting up the related entrypoints for the native app
 * to call back to with a response.
 *
 * @param {string} functionName used to organize function calls in each of their own arrays on the DOM that can be
 *                              called back to when the app has a response.
 * @param {object} parameters various additional parameters to pass with the function call
 *
 * @return {Promise} A promse that will be resolved when the app calls back with success, or rejected when the app
 *                   calls back with failure. If the app sends a response, it will be in string format and will need
 *                   to be parsed in order to process the data (parsing happens in calling function).
 *
 */
async function callNativeApp(functionName: string, parameters: object): Promise<string> {
    const response: Promise<string> = new Promise((resolve, reject) => {
        const responseIndex = window.amzn[`_${functionName}`].push({ resolve, reject }) - 1;
        const successCallback = `window.amzn._${functionName}[${responseIndex}].resolve`;
        const errorCallback = `window.amzn._${functionName}[${responseIndex}].reject`;
        if (window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers[functionName]) {
            // iOS expects parameters to be passed as an object to its interface method.
            const iOSParameters = {
                ...parameters,
                successCallback,
                errorCallback,
            };
            window.webkit.messageHandlers[functionName].postMessage(iOSParameters);
        } else if (window.Android && window.Android[functionName]) {
            // Android only takes primitive values for parameters, so we will sort parameters by parameter name to
            // ensure consistent ordering of parameters, map them to values, then prepend the successCallback and
            // errorCallback to the list of parameters passed into the interface method.
            const sortedParameterValues = Object.entries(parameters)
                .sort((a, b) => (a[0] < b[0] ? -1 : 1))
                .map((entry) => entry[1]);
            const androidParameters = [successCallback, errorCallback, ...sortedParameterValues];
            window.Android[functionName](...androidParameters);
        } else {
            throw new Error('App interface does not exist');
        }
    });
    return response;
}

/**
 * Calls through to Contact SDK's getAccount to get the account info of the customer.
 *
 * @return {Promise<GetAccountResponse>} Promise that is resolved with the GetAccountResponse from the Contact
 * SDK's getAccount call on success (or rejected on failure)
 */
export async function getAccount(): Promise<GetAccountResponse> {
    return callNativeApp('getAccount', {})
        .then(JSON.parse)
        .then(({ accounts, customerIdentity }: GetAccountInternalResponse) => {
            const possibleAccounts = accounts || customerIdentity?.accounts;
            const currentAccount = possibleAccounts?.find((account) => account.signedInUser);
            if (currentAccount) {
                return {
                    currentAccount: {
                        actorId: currentAccount.actorId || currentAccount.mapDirectedDefaultActorId,
                        signedInUser: currentAccount.signedInUser,
                        phoneNumber: currentAccount.phoneNumber,
                    },
                };
            }
            return { currentAccount: undefined };
        });
}

/**
 * Calls through to Contact SDK's getContacts to get a list of Contacts containing matched music profile ids where
 * they exist
 *
 * @return {Promise<GetContactsResponse>} Promise that is resolved with the GetContactsResponse from the Contact
 * SDK's getContacts call on success (or rejected on failure)
 */
export async function getContacts(): Promise<GetContactsResponse> {
    return callNativeApp('getContacts', {})
        .then((response: string) => JSON.parse(response) as GetContactsResponse);
}

/**
 * Calls through to native app to get necessary customer info needed for downstream API calls.
 *
 * @return {Promise<GetCustomerInfoResponse>} Promise that is resolved with the necessary customer info upon successful
 * call (or rejected if something goes wrong).
 */
export async function getCustomerInfo(): Promise<GetCustomerInfoResponse> {
    return callNativeApp('getCustomerInfo', {})
        .then((response: string) => JSON.parse(response) as GetCustomerInfoResponse);
}

/**
 * Calls through to Contact SDK's getPhoneNumberVerificationURL to get the URL to navigate to in order to verify the
 * customer's phone number is uploaded and accessible for matching with contacts.
 *
 * @param {string} redirectURL The URL to be redirected to upon successful completion of the phone verification flow
 * @param {string} actorId The actorId to verify phone number for. Since music doesn't use actorIds, we get this from
 *                         the getAccount call.
 *
 * @return {Promise<GetPhoneNumberVerificationURLResponse>} Promise that is resolved with the
 * GetPhoneNumberVerificationURLResponse from the Contact SDK's getPhoneNumberVerificationURL call on success (or
 * rejected on failure).
 */
export async function getPhoneNumberVerificationURL(
    requestRedirectURL: string,
    actorId: string,
): Promise<GetPhoneNumberVerificationURLResponse> {
    return callNativeApp('getPhoneNumberVerificationURL', { redirectURL: requestRedirectURL, actorId })
        .then(JSON.parse)
        .then(({ nextStep, redirectURL, redirectUrl }: GetPhoneNumberVerificationURLInternalResponse) => ({
            nextStep,
            redirectURL: redirectUrl || redirectURL,
        }));
}

/**
 * Calls through to Contact SDK's getPreferenceForOwner to get the value for a particular preference
 *
 * @param {PreferenceKey} key The key of the preference to get
 * @param {PreferenceTarget|string} target The target to get the value of. Will be device id (string type) for the
 *                                  CONTACT_IMPORT key. Otherwise will be a PreferenceTarget.
 *
 * @return {Promise<GetPreferenceForOwnerResponse>} Promise that is resolved with the GetPreferenceForOwnerResponse
 * from the Contact SDK's getPreferenceForOwner call on success (or rejected on failure).
 */
export async function getPreferenceForOwner(
    key: PreferenceKey,
    target: PreferenceTarget|string,
): Promise<GetPreferenceForOwnerResponse> {
    return callNativeApp('getPreferenceForOwner', { key, target })
        .then((response: string) => JSON.parse(response) as GetPreferenceForOwnerResponse);
}

/**
 * Calls through to the native app to open the OS settings page. This is called in cases where the call to
 * requestOSContactsPermissions fails.
 *
 * @return {Promise} Promise that is resolved upon navigation to settings.
 */
export async function openOSAppSettings(): Promise<string> {
    return callNativeApp('openOSAppSettings', {});
}

/**
 * Calls through to the native app to request contact permissions from the OS.
 *
 * @return {Promise} Promise that is resolved upon successfully being granted permissions (or rejected if denied)
 */
export async function requestOSContactsPermissions(): Promise<string> {
    return callNativeApp('requestOSContactsPermissions', {});
}

/**
 * Calls through to Contact SDK's setPreferenceForOwner to set a particular preference value.
 *
 * @param {PreferenceKey} key The key of the preference to set
 * @param {PreferenceTarget|string} target The target to set the value of. Will be device id (string type) for the
 *                                  CONTACT_IMPORT key. Otherwise will be a PreferenceTarget.
 * @param {PreferenceValue} value The value to set for the key/target combination
 *
 * @return {Promise} Promise that is resolved upon successful setting of preference (or rejected upon failure)
 */
export async function setPreferenceForOwner(
    key: PreferenceKey,
    target: PreferenceTarget|string,
    value: PreferenceValue,
): Promise<string> {
    return callNativeApp('setPreferenceForOwner', { key, target, value });
}

/**
 * Calls through to Contact SDK's startContactsUpSync to upload phone contacts to server for matching.
 *
 * @return {Promise} Promise that is resolved upon successful upload (or rejected upon failure)
 */
export async function startContactsUpSync(): Promise<string> {
    return callNativeApp('startContactsUpSync', {});
}

export async function shareProfile(profileId: string, profileName: string): Promise<string> {
    return callNativeApp('shareProfile', { profileId, profileName });
}
