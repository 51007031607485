import axios from 'axios';
import { Component, Fragment } from 'preact';
import {
    getUser,
    updateUser,
    getUserPlaylists,
    updatePlaylist,
} from '../services/firefly/FireFlyInterface';
import { User, Playlist, VisibilityType } from '../types';

interface State {
    result?: string
    calledFunction?: string
    api: string
    accessToken?: string;
    authCode?: string;
}

interface APIParams {
    entry: 'input'
    name: 'name'|'visibility'|'playbackVisibility'|'limit'|'playlistId'|'title'|'description'
    values: []
    defaultValue?: number
}

type APIResponse = User
    |Playlist
    |Playlist[]
    |string;

interface APIProps {
    name: string
    func: (...args: (string|number|undefined|VisibilityType)[]) => Promise<APIResponse>
    params: APIParams[]
}

interface InterfaceAPIs {
    [api: string]: APIProps
}

const interfaceAPIs: InterfaceAPIs = {
    getUser: {
        name: 'Get User Profile',
        func: getUser,
        params: [],
    },
    updateUser: {
        name: 'UpdateUserProfile',
        func: updateUser,
        params: [{
            entry: 'input',
            name: 'name',
            values: [],
        }, {
            entry: 'input',
            name: 'visibility',
            values: [],
        }, {
            entry: 'input',
            name: 'playbackVisibility',
            values: [],
        }],
    },
    getUserPlaylists: {
        name: 'GetUserPlaylists',
        func: getUserPlaylists,
        params: [{
            entry: 'input',
            name: 'limit',
            values: [],
            defaultValue: 5,
        }],
    },
    updatePlaylist: {
        name: 'UpdatePlaylist',
        func: updatePlaylist,
        params: [{
            entry: 'input',
            name: 'playlistId',
            values: [],
        }, {
            entry: 'input',
            name: 'title',
            values: [],
        }, {
            entry: 'input',
            name: 'description',
            values: [],
        }, {
            entry: 'input',
            name: 'visibility',
            values: [],
        }],
    },
};

const clientId = 'amzn1.application-oa2-client.a6d8bbe5f9f84273970b3c886269cde0';
const clientSecret = '8399aa64360e69f9f6e9f3d76114337f1e686450bac82b562284322273e766bb';
const redirectUrl = 'http://localhost:4000/testFirefly';
const accessTokenEndpoint = 'https://api.amazon.com/auth/o2/token';

interface Amazon {
    Login: {
        authorize: (options: {[key: string]: string|boolean }, redirectUrl: string) => void
        setClientId: (clientId: string) => void
    }
}

declare let amazon: Amazon;

/**
 * A page that can be used to test integrations with the FireFly interface before the flow is fully implemented.
 */
class FireFlyInterfaceTestPage extends Component<object, State> {
    constructor(props) {
        super(props);
        this.setState({
            api: Object.keys(interfaceAPIs)[0],
        });
    }

    // load amazon-login-sdk
    componentDidMount() {
        const loginScript = document.createElement('script');
        loginScript.type = 'text/javascript';
        loginScript.async = true;
        loginScript.id = 'amazon-login-sdk';
        loginScript.src = 'https://assets.loginwithamazon.com/sdk/na/login1.js';
        document.getElementById('amazon-root')?.appendChild(loginScript);
    }

    // Click handlers
    private async requestAuthCode() {
        amazon.Login.setClientId(clientId);

        const options = {
            response_type: 'code',
            popup: false,
            scope: 'profile',
        };
        amazon.Login.authorize(options, redirectUrl);
    }

    private async requestAccessToken() {
        const url = window.location.href;
        const params = url.split('?')[1];
        const authCode = params.split('&')[0].split('=')[1];
        this.setState({ authCode });

        await this.sleep(1);

        let data = `grant_type=authorization_code&code=${this.state.authCode}`;
        data = data.concat(`&redirect_uri=${redirectUrl}&client_id=${clientId}&client_secret=${clientSecret}`);

        axios({
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
            method: 'POST',
            url: `${accessTokenEndpoint}`,
            data: `${data}`,
        }).then((response) => {
            this.setState({ accessToken: response.data.access_token });
        });
    }

    private sleep(milliSec : number) {
        return new Promise((resolve) => {
            setTimeout(resolve, milliSec);
        });
    }

    private async handleApi({ func, params }) {
        const parameters = params.map(({ name }) => (document.querySelector(
            `#${this.state.api} .parameter-${name}`,
        ) as HTMLInputElement|HTMLSelectElement)?.value || undefined);
        const additionalParams = [this.state.accessToken, '', ''];
        const inputParams = additionalParams.concat(parameters);
        const calledFunction = `${this.state.api}(${inputParams})`;
        try {
            const result = await func(...inputParams);
            const formattedResult = JSON.stringify(result, null, 2) || 'Success';
            this.setState({ calledFunction, result: formattedResult });
        } catch (error) {
            this.setState({ calledFunction, result: error.message });
        }
    }

    private handleAPISelection({ target }) {
        this.setState({
            api: target.value,
            result: undefined,
            calledFunction: undefined,
        });
    }

    // Render functions
    private getAuthCode() {
        const buttonText = 'Login with Amazon - get Authorization Code';
        return (
            <div id='auth-code'>
                <button className='auth-code-button' onClick={() => this.requestAuthCode()}>
                    {buttonText}
                </button>
            </div>
        );
    }

    private getAccessToken() {
        const buttonText = 'Login with Amazon - get Access Token';
        return (
            <div id='access-token'>
                <button className='access-token-button' onClick={() => this.requestAccessToken()}>
                    {buttonText}
                </button>
            </div>
        );
    }

    private printTokens() {
        const authCode = this.state.authCode
            ? <div id='auth-code'>
                <span>Authorization Code:</span>
                {this.state.authCode}
            </div> : '';
        const accessToken = this.state.accessToken
            ? <div id='access-token'>
                <span>Access Token:</span>
                {this.state.accessToken}
            </div> : '';
        return (
            <div id='print-tokens'>
                {authCode}
                {accessToken}
            </div>
        );
    }

    private getAPISelector() {
        const options = Object.entries(interfaceAPIs).map(([key, { name }]) => <option value={key}>{name}</option>);
        return (
            <div id='api-select'>
                <span>API: </span>
                <select onChange={(event) => this.handleAPISelection(event)} defaultValue={this.state.api}>
                    {options}
                </select>
            </div>
        );
    }

    private getAPIForm() {
        const api = interfaceAPIs[this.state.api];
        const parameters = api.params.map(({
            entry,
            name,
            defaultValue,
        }) => {
            switch (entry) {
            case 'input':
                return (
                    <div className={`param parameter-input-${name}`}>
                        <span className={`parameter-label-${name}`}>{name}: </span>
                        <input className={`parameter-${name}`} defaultValue={defaultValue}/>
                    </div>
                );
            default:
                return `Unsupported parameter type: ${entry}`;
            }
        });
        const parameterKeys = api.params.map(({ name }) => name).join(', ');
        const buttonText = `Test ${this.state.api}(${parameterKeys})`;
        return (
            <div id={this.state.api} className='api'>
                <div className='parameters'>
                    <div className='label'>Parameters:</div>
                    <div className='values'>{parameters.length ? parameters : 'None'}</div>
                </div>
                <button className='test-button' onClick={() => this.handleApi(api)}>
                    {buttonText}
                </button>
            </div>
        );
    }

    private getResult() {
        const functionCall = this.state.calledFunction
            ? <div id='function-call'>
                <span>Function Called: </span>
                {this.state.calledFunction}
            </div> : '';
        const result = this.state.result
            ? <div id='result'>
                <span>Result:</span>
                <pre id='result-value'>
                    {this.state.result}
                </pre>
            </div> : '';
        return (
            <div id='output'>
                {functionCall}
                {result}
            </div>
        );
    }

    private getStyle() {
        return <style>{`
            body {
                background-color: white;
            }
            body, button, select {
                font-size: 32px;
            }
            .param {
                padding-top: 16px;
            }
            #api-select {
                padding-bottom: 48px;
            }
            .parameters {
                padding-bottom: 48px;
            }
            .parameter-select {
                padding-bottom: 16px;
            }
            .api {
                padding-bottom: 80px;
            }
            #mock-interface {
                padding-bottom: 64px;
            }
            #auth-code {
                padding-bottom: 30px;
            }
            #access-token {
                padding-bottom: 30px;
            }
            #print-tokens {
                padding-bottom: 30px;
            }
        `}</style>;
    }

    render() {
        return (
            <Fragment>
                {this.getStyle()}
                <div id="amazon-root"></div>
                {this.getAuthCode()}
                {this.getAccessToken()}
                <div id='api'>
                    {this.getAPISelector()}
                    {this.getAPIForm()}
                </div>
                {this.printTokens()}
                {this.getResult()}
            </Fragment>
        );
    }
}

export default FireFlyInterfaceTestPage;
