import { Component, Fragment } from 'preact';
import {
    getAccount,
    getContacts,
    getCustomerInfo,
    getPhoneNumberVerificationURL,
    getPreferenceForOwner,
    openOSAppSettings,
    requestOSContactsPermissions,
    setPreferenceForOwner,
    shareProfile,
    startContactsUpSync,
} from '../native/AppInterface';
import { getUser } from '../services/firefly/FireFlyInterface';
import {
    GetAccountResponse,
    GetContactsResponse,
    GetCustomerInfoResponse,
    GetPhoneNumberVerificationURLResponse,
    GetPreferenceForOwnerResponse,
    PreferenceKey,
    PreferenceTarget,
    PreferenceValue,
    User,
} from '../types';

interface State {
    result?: string
    calledFunction?: string
    link?: string
    api: string
}

interface APIParams {
    entry: 'select'|'input'
    name: 'key'|'target'|'value'|'redirectURL'|'actorId'|'accessToken'|'deviceId'|'deviceType'|'profileId'|'profileName'
    values: string[]
    defaultValue?: string
}

type ApiResponse = GetAccountResponse
    |GetContactsResponse
    |GetCustomerInfoResponse
    |GetPhoneNumberVerificationURLResponse
    |GetPreferenceForOwnerResponse
    |User
    |string;

interface APIProps {
    name: string
    func: (...args: string[]) => Promise<ApiResponse>
    disabledReason?: string
    params: APIParams[]
}

interface InterfaceAPIs {
    [api: string]: APIProps
}

const interfaceAPIs: InterfaceAPIs = {
    getAccount: {
        name: 'Get Account',
        func: getAccount,
        params: [],
    },
    getContacts: {
        name: 'Get Contacts',
        func: getContacts,
        params: [],
    },
    getCustomerInfo: {
        name: 'Get Customer Info',
        func: getCustomerInfo,
        params: [],
    },
    getPhoneNumberVerificationURL: {
        name: 'Get Phone Number Verification URL',
        func: getPhoneNumberVerificationURL,
        disabledReason: 'Call getAccount First to Init actorId',
        params: [{
            entry: 'input',
            name: 'redirectURL',
            values: [],
            defaultValue: window.location.href,
        }, {
            entry: 'select',
            name: 'actorId',
            values: [],
        }],
    },
    getPreferenceForOwner: {
        name: 'Get Preference For Owner',
        func: getPreferenceForOwner,
        params: [
            {
                entry: 'select',
                name: 'key',
                values: Object.values(PreferenceKey),
                defaultValue: PreferenceKey.AUTO_DISCOVERY,
            },
            {
                entry: 'select',
                name: 'target',
                values: Object.values(PreferenceTarget),
                defaultValue: PreferenceTarget.AMAZON_MUSIC,
            },
        ],
    },
    openOSAppSettings: {
        name: 'open OS App Settings',
        func: openOSAppSettings,
        params: [],
    },
    requestOSContactsPermissions: {
        name: 'Request OS Contacts Permissions',
        func: requestOSContactsPermissions,
        params: [],
    },
    setPreferenceForOwner: {
        name: 'Set Preference For Owner',
        func: setPreferenceForOwner,
        params: [
            {
                entry: 'select',
                name: 'key',
                values: Object.values(PreferenceKey),
                defaultValue: PreferenceKey.AUTO_DISCOVERY,
            },
            {
                entry: 'select',
                name: 'target',
                values: Object.values(PreferenceTarget),
                defaultValue: PreferenceTarget.AMAZON_MUSIC,
            },
            {
                entry: 'select',
                name: 'value',
                values: Object.values(PreferenceValue),
                defaultValue: PreferenceValue.ENABLED,
            },
        ],
    },
    startContactsUpSync: {
        name: 'Start Contacts Up Sync',
        func: startContactsUpSync,
        params: [],
    },
    getUser: {
        name: 'Get User Profile',
        func: getUser,
        disabledReason: 'Call getCustomerInfo First to Init accessToken, deviceId, deviceType',
        params: [{
            entry: 'select',
            name: 'accessToken',
            values: [],
        }, {
            entry: 'select',
            name: 'deviceId',
            values: [],
        }, {
            entry: 'select',
            name: 'deviceType',
            values: [],
        }],
    },
    shareProfile: {
        name: 'Share Profile',
        func: shareProfile,
        disabledReason: 'Call getProfile first to init profile params',
        params: [{
            entry: 'select',
            name: 'profileId',
            values: [],
        }, {
            entry: 'select',
            name: 'profileName',
            values: [],
        }],
    },
};

/**
 * A page that can be used to test integrations with the app interface before the flow is fully implemented.
 */
class AppInterfaceTestPage extends Component<object, State> {
    constructor(props) {
        super(props);
        this.setState({
            api: Object.keys(interfaceAPIs)[0],
        });
    }

    // Helper function to attempt to populate device id for getPreferenceForOwner and setPreferenceForOwner when
    // the api is selected
    private populatePreferenceForOwnerDeviceIds(result: GetCustomerInfoResponse) {
        if (!interfaceAPIs.getPreferenceForOwner.params[1].values.includes(result.deviceId)) {
            interfaceAPIs.getPreferenceForOwner.params[1].values.push(result.deviceId);
        }
        if (!interfaceAPIs.setPreferenceForOwner.params[1].values.includes(result.deviceId)) {
            interfaceAPIs.setPreferenceForOwner.params[1].values.push(result.deviceId);
        }
    }

    // Helper function to attempt to populate actor id for getPhoneNumberVerificationURL when the api is selected
    private populatePhoneNumberverificationActorId(result: GetAccountResponse) {
        if (result.currentAccount?.actorId
            && !interfaceAPIs.getPhoneNumberVerificationURL.params[1].values.includes(
                result.currentAccount?.actorId,
            )) {
            interfaceAPIs.getPhoneNumberVerificationURL.params[1].values
                .push(result.currentAccount?.actorId);
            interfaceAPIs.getPhoneNumberVerificationURL.params[1].defaultValue = result
                .currentAccount?.actorId;
            delete interfaceAPIs.getPhoneNumberVerificationURL.disabledReason;
        }
    }

    // Helper function to attempt to populate accessToken, deviceId, deviceType for getUser when the api is selected
    private populateAuthenticationInfoForFireFlyAPIs(result: GetCustomerInfoResponse) {
        // always push the oAuthToken because it expires every 1 hour
        interfaceAPIs.getUser.params[0].values = [result.oAuthToken];

        if (!interfaceAPIs.getUser.params[1].values.includes(result.deviceId)) {
            interfaceAPIs.getUser.params[1].values.push(result.deviceId);
        }

        if (!interfaceAPIs.getUser.params[2].values.includes(result.deviceType)) {
            interfaceAPIs.getUser.params[2].values.push(result.deviceType);
        }
        delete interfaceAPIs.getUser.disabledReason;
    }

    private populateProfileParamsForShareProfile(result: User) {
        if (!interfaceAPIs.shareProfile.params[0].values.includes(result.id)) {
            interfaceAPIs.shareProfile.params[0].values.push(result.id);
        }
        if (!interfaceAPIs.shareProfile.params[1].values.includes(result.name)) {
            interfaceAPIs.shareProfile.params[1].values.push(result.name);
        }
        delete interfaceAPIs.shareProfile.disabledReason;
    }

    // Click Handlers
    private async handleApi({ func, params }) {
        const parameters = params.map(({ name }) => (document.querySelector(
            `#${this.state.api} .parameter-${name}`,
        ) as HTMLInputElement|HTMLSelectElement)?.value);
        const calledFunction = `${this.state.api}(${parameters.map(JSON.stringify).join(', ')})`;
        try {
            const result = await func(...parameters);
            // On call to get customer info populate getPreferenceForOwner and setPreferenceForOwner deviceId as a
            // possible target value.
            // On call to get customer info populate getUser accessToken, deviceId and deviceType as possible target
            // values.
            // On call to getAccount, populate getPhoneNumberVerificationURL actorId as a possible target value.
            if (this.state.api === 'getCustomerInfo') {
                this.populatePreferenceForOwnerDeviceIds(result as GetCustomerInfoResponse);
                this.populateAuthenticationInfoForFireFlyAPIs(result as GetCustomerInfoResponse);
            } else if (this.state.api === 'getAccount') {
                this.populatePhoneNumberverificationActorId(result as GetAccountResponse);
            } else if (this.state.api === 'getPhoneNumberVerificationURL') {
                this.setState({ link: (result as GetPhoneNumberVerificationURLResponse).redirectURL });
            } else if (this.state.api === 'getUser') {
                this.populateProfileParamsForShareProfile(result as User);
            }
            const formattedResult = JSON.stringify(result, null, 2) || 'Success';
            this.setState({ calledFunction, result: formattedResult });
        } catch (error) {
            this.setState({ calledFunction, result: error.message });
        }
    }

    private handleAPISelection({ target }) {
        this.setState({
            api: target.value,
            result: undefined,
            calledFunction: undefined,
            link: undefined,
        });
    }

    // Render functions
    private getAPISelector() {
        const options = Object.entries(interfaceAPIs).map(([key, { name }]) => <option value={key}>{name}</option>);
        return (
            <div id='api-select'>
                <span>API: </span>
                <select onChange={(event) => this.handleAPISelection(event)} defaultValue={this.state.api}>
                    {options}
                </select>
            </div>
        );
    }

    private getAPIForm() {
        const api = interfaceAPIs[this.state.api];
        const parameters = api.params.map(({
            entry,
            name,
            values,
            defaultValue,
        }) => {
            switch (entry) {
            case 'select':
                return (
                    <div className={`param parameter-select-${name}`}>
                        <span className={`parameter-label-${name}`}>{name}: </span>
                        <select className={`parameter-${name}`} defaultValue={defaultValue}>
                            { values.map((value) => <option value={value}>{value}</option>) }
                        </select>
                    </div>
                );
                break;
            case 'input':
                return (
                    <div className={`param parameter-input-${name}`}>
                        <span className={`parameter-label-${name}`}>{name}: </span>
                        <input className={`parameter-${name}`} defaultValue={defaultValue} />
                    </div>
                );
            default:
                return `Unsupported parameter type: ${entry}`;
            }
        });
        const parameterKeys = api.params.map(({ name }) => name).join(', ');
        const buttonText = api.disabledReason
            ? api.disabledReason
            : `Test ${this.state.api}(${parameterKeys})`;
        return (
            <div id={this.state.api} className='api'>
                <div className='parameters'>
                    <div className='label'>Parameters:</div>
                    <div className='values'>{parameters.length ? parameters : 'None'}</div>
                </div>
                <button className='test-button' onClick={() => this.handleApi(api)} disabled={!!api.disabledReason}>
                    {buttonText}
                </button>
            </div>
        );
    }

    private getResult() {
        const functionCall = this.state.calledFunction
            ? <div id='function-call'>
                <span>Function Called: </span>
                {this.state.calledFunction}
            </div> : '';
        const result = this.state.result
            ? <div id='result'>
                <span>Result:</span>
                <pre id='result-value'>
                    {this.state.result}
                </pre>
            </div> : '';
        const link = this.state.link
            ? <div id='result-link'>
                <a target='_self' href={this.state.link}>{this.state.link}</a>
            </div> : '';
        return (
            <div id='output'>
                {functionCall}
                {result}
                {link}
            </div>
        );
    }

    private getStyle() {
        return <style>{`
            body {
                background-color: white;
            }
            body, button, select {
                font-size: 16px;
            }
            .param {
                padding-top: 8px;
            }
            #api-select {
                padding-bottom: 24px;
            }
            .parameters {
                padding-bottom: 24px;
            }
            .parameter-select {
                padding-bottom: 8px;
            }
            .api {
                padding-bottom: 40px;
            }
            #mock-interface {
                padding-bottom: 32px;
            }
        `}</style>;
    }

    render() {
        return (
            <Fragment>
                {this.getStyle()}
                <div id='api'>
                    {this.getAPISelector()}
                    {this.getAPIForm()}
                </div>
                {this.getResult()}
            </Fragment>
        );
    }
}

export default AppInterfaceTestPage;
