import { Component } from 'preact';
// Recommended to not add history as direct dependency to avoid version conflicts breaking the singleton behavior
// https://reactrouter.com/docs/en/v6/routers/history-router
import history from 'history/browser'; // eslint-disable-line import/no-extraneous-dependencies
import MusicTextInputWrapper from './components/MusicTextInputWrapper';
import {
    getAccount,
    getCustomerInfo,
    setPreferenceForOwner,
    getPhoneNumberVerificationURL,
} from '../native/AppInterface';
import { getUser, updateUser } from '../services/firefly/FireFlyInterface';
import {
    PreferenceKey,
    PreferenceTarget,
    PreferenceValue,
    User,
    VisibilityType,
} from '../types';
import {
    getStrings,
    LocalizedStrings,
    PATHS,
    QUERY,
    BROWSE_HOME,
} from '../util';

interface State {
    user: User
    contactDiscovery: PreferenceValue
    oAuthToken: string
    deviceId: string
    deviceType: string
    strings: LocalizedStrings
    showContactDiscoveryLearnMore: boolean
    showProfileNameLearnMore: boolean
    phoneVerificationUrl: string
}

class ProfileSetupPage extends Component<object, State> {
    private async getCurrentProfile() {
        // TODO: Should probably centralize state of managing oAuthToken, deviceId, and deviceType
        const {
            oAuthToken,
            deviceId,
            deviceType,
            locale,
        } = await getCustomerInfo();
        const user = await getUser(
            oAuthToken,
            deviceId,
            deviceType,
        );
        // Default playlist visibility, profile visibility, and contact discovery toggles to on
        user.playbackVisibility = VisibilityType.PUBLIC;
        user.visibility = VisibilityType.PUBLIC;
        this.setState({
            user,
            oAuthToken,
            deviceId,
            deviceType,
            contactDiscovery: PreferenceValue.ENABLED,
            strings: getStrings(locale),
            showContactDiscoveryLearnMore: false,
            showProfileNameLearnMore: false,
        });
        const { currentAccount } = await getAccount();
        // TODO: Throw error rather than defaulting to empty string when actor id doesn't exist.
        const { redirectURL } = await getPhoneNumberVerificationURL(
            `${window.location.origin}/${PATHS.PLAYLIST_VISIBILITY}?${QUERY.PHONE_NUMBER_VERIFIED}`,
            currentAccount?.actorId || '',
        );
        this.setState({
            phoneVerificationUrl: redirectURL,
        });
    }

    constructor(props) {
        super(props);
        this.getCurrentProfile();
    }

    private isListeningHistoryPublic() {
        return this.state.user.playbackVisibility !== VisibilityType.PRIVATE;
    }

    private toggleListeningHistory(event) {
        const playbackVisibility: VisibilityType = this.isListeningHistoryPublic()
            ? VisibilityType.PRIVATE
            : VisibilityType.PUBLIC;
        const user = { ...this.state.user, playbackVisibility };
        this.setState({ user });
        // Prevent click from toggling input when press is direclty on toggle as that breaks state
        event.preventDefault();
    }

    private isContactDiscoveryEnabled() {
        return this.state.contactDiscovery !== PreferenceValue.DISABLED;
    }

    private toggleContactDiscovery(event) {
        const contactDiscovery = this.isContactDiscoveryEnabled() ? PreferenceValue.DISABLED : PreferenceValue.ENABLED;
        this.setState({ contactDiscovery });
        // Prevent click from toggling input when press is direclty on toggle as that breaks state
        event.preventDefault();
    }

    private updateProfileVisibility(visibility: VisibilityType) {
        const user = { ...this.state.user, visibility };
        this.setState({ user });
    }

    private showContactDiscoveryLearnMore(event) {
        this.setState({ showContactDiscoveryLearnMore: true });
        // Clicking this link should only display the learn more dialog and not trigger the toggle
        event.stopImmediatePropagation();
    }

    private hideContactDiscoveryLearnMore(event) {
        this.setState({ showContactDiscoveryLearnMore: false });
        // Should only close dialog and not click through to other elements on page below the modal
        event.stopImmediatePropagation();
    }

    private showProfileNameLearnMore(event) {
        this.setState({ showProfileNameLearnMore: true });
        // Clicking this link should only display the learn more dialog and not trigger the toggle
        event.stopImmediatePropagation();
    }

    private hideProfileNameLearnMore(event) {
        this.setState({ showProfileNameLearnMore: false });
        // Should only close dialog and not click through to other elements on page below the modal
        event.stopImmediatePropagation();
    }

    private getToggle(primaryText: string, secondaryText: string, id: string, onClick: () => void, checked: boolean) {
        return <div onclick={onClick} class='toggle-line'>
            <div class='toggle-description'>
                <div class="primary-text">{primaryText}</div>
                <div class='secondary-text'>{secondaryText}</div>
            </div>
            <music-toggle id={id} class='profile-toggle' checked={checked}></music-toggle>
        </div>;
    }

    private getRadioButton(primaryText: string, secondaryText: string, checked: boolean, onClick: () => void) {
        return <music-radio-button onclick={onClick} name={primaryText} checked={checked}>
            <div class='primary-text'>{primaryText}</div>
            <div class='secondary-text'>{secondaryText}</div>
        </music-radio-button>;
    }

    private getFullscreenModal(id: string, visible: boolean, close: () => void, titleText: string, bodyText: string) {
        return <div id={id} class={`fullscreen-modal ${visible ? '' : 'hidden'}`}>
            <div class='background-blur'/>
            <div class='modal-contents'>
                <div class='modal-close-container'>
                    <music-button class='modal-close'
                        ariaLabelText={this.state.strings.setupProfileLearnMoreGotIt}
                        variant='glass'
                        onClick={close}
                        icon-name='cancel'
                        icon-only={true}
                        size='medium'/>
                </div>
                <h2 class='music-headline-4 modal-header'>
                    {titleText}
                </h2>
                <div class='secondary-text modal-body'>
                    {bodyText}
                </div>
                <music-button class='modal-button'
                    ariaLabelText={this.state.strings.setupProfileLearnMoreGotIt}
                    variant='outline'
                    onClick={close}
                    remove-text-transform={true}
                    size='large'>
                    <span class='modal-button-text'>{this.state.strings.setupProfileLearnMoreGotIt}</span>
                </music-button>
            </div>
        </div>;
    }

    private submitDisabled() {
        return !this.state.user.name;
    }

    private async submit() {
        // The button component's disabled state only modifies the UX. The onClick event still fires, so we need to
        // exit early here.
        if (this.submitDisabled()) {
            return;
        }
        const updateUserPromise = updateUser(
            this.state.oAuthToken,
            this.state.deviceId,
            this.state.deviceType,
            this.state.user.name,
            this.state.user.visibility,
            this.state.user.playbackVisibility,
        );
        const setPreferencePromise = setPreferenceForOwner(
            PreferenceKey.AUTO_DISCOVERY,
            PreferenceTarget.ALL,
            this.state.contactDiscovery,
        );
        await Promise.all([updateUserPromise, setPreferencePromise]);
        this.navigateToNextPage();
    }

    private navigateToNextPage() {
        if (this.state.user.visibility === VisibilityType.PUBLIC) {
            if (this.state.contactDiscovery === PreferenceValue.ENABLED) {
                if (this.state.phoneVerificationUrl) {
                    window.location.href = this.state.phoneVerificationUrl;
                    return;
                } // else {
                // TODO: Add error metric for missing phoneVerificationUrl
                // }
            }
            // If profile is public, but contacts are disabled or we fail to get phone verification URL go to playlist
            // visibility page
            history.push(`/${PATHS.PLAYLIST_VISIBILITY}`);
        } else {
            // If profile is private - exit flow
            window.location.href = BROWSE_HOME;
        }
    }

    private onNameChanged(event) {
        const { user } = this.state;
        user.name = event.target.value;
        this.setState({ user });
    }

    render() {
        // Wait until state is loaded before rendering content.
        if (!this.state.strings) {
            return <div class='loading-spinner-wrapper'>
                <music-icon size="xl" name="loader"/>
            </div>;
        }
        const nameText = <span>
            {this.state.strings.setupProfileNameDescription}
            <a onClick={this.showProfileNameLearnMore.bind(this)}>{this.state.strings.setupProfileLearnMore}</a>
        </span>;
        const discoverySecondary = <span>
            {this.state.strings.setupProfileContactDiscoveryDescription}
            <a onclick={this.showContactDiscoveryLearnMore.bind(this)}>{this.state.strings.setupProfileLearnMore}</a >
        </span>;
        const image = this.state.user.images[0]?.url ? <div class='image-container'>
            <music-vertical-item
                imageSrc={this.state.user.images[0].url}
                kind='circle'
                parentSize='small'
                role='editProfile'
                showActionButton={false} />
        </div> : '';
        // TODO: Continue button should probably disabled prior to entering name
        // TODO: Styling seems a bit off still - may need to scale stuff down to fit on one screen.
        return <div id="setup-profile" class='page-contents'>
            <header class='profile-header'>
                <h1 class='music-headline-4'>{this.state.strings.setupProfileHeader}</h1>
                <div class='secondary-text subtext'>{this.state.strings.setupProfileSubHeader}</div>
                {image}
            </header>
            <MusicTextInputWrapper id='name-input'
                value={this.state.user.name}
                onInput={this.onNameChanged.bind(this)}
                labelText={this.state.strings.setupProfileProfileName} />
            <div class='secondary-text name-text'>{nameText}</div>
            <div id='profile-visibility'>
                {this.getRadioButton(
                    this.state.strings.setupProfilePublicRadioTitle,
                    this.state.strings.setupProfilePublicRadioDescription,
                    this.state.user.visibility === VisibilityType.PUBLIC,
                    this.updateProfileVisibility.bind(this, VisibilityType.PUBLIC),
                )}
                {this.getRadioButton(
                    this.state.strings.setupProfilePrivateRadioTitle,
                    this.state.strings.setupProfilePrivateRadioDescription,
                    this.state.user.visibility === VisibilityType.PRIVATE,
                    this.updateProfileVisibility.bind(this, VisibilityType.PRIVATE),
                )}
            </div>
            {this.getToggle(
                this.state.strings.setupProfileListeningHistoryTitle,
                this.state.strings.setupProfileListeningHistoryDescription,
                'playback-visibility',
                this.toggleListeningHistory.bind(this),
                this.isListeningHistoryPublic(),
            )}
            {this.getToggle(
                this.state.strings.setupProfileContactDiscoveryTitle,
                discoverySecondary,
                'contact-discovery',
                this.toggleContactDiscovery.bind(this),
                this.isContactDiscoveryEnabled(),
            )}
            {this.getFullscreenModal.bind(this)(
                'profile-name-learn-more',
                this.state.showProfileNameLearnMore,
                this.hideProfileNameLearnMore.bind(this),
                this.state.strings.setupProfileNameLearnMoreTitle,
                this.state.strings.setupProfileNameLearnMoreDescription,
            )}
            {this.getFullscreenModal.bind(this)(
                'contact-discovery-learn-more',
                this.state.showContactDiscoveryLearnMore,
                this.hideContactDiscoveryLearnMore.bind(this),
                this.state.strings.setupProfileContactDiscoveryTitle,
                this.state.strings.setupProfileContactDiscoveryLearnMoreDescription,
            )}
            <music-button class='primary-button'
                ariaLabelText={this.state.strings.setupProfileSubmitButton}
                disabled={this.submitDisabled.bind(this)()}
                variant='solid'
                onClick={this.submit.bind(this)}
                size='large'>{this.state.strings.setupProfileSubmitButton}</music-button>
        </div>;
    }
}

export default ProfileSetupPage;
