export type ImageType = 'BACKGROUND' | 'PRIMARY' | 'PROFILE';

export class Image {
    /**
     * Url of the image asset.
     */
    url: string;

    /**
     * Width of the image in pixels.
     */
    width?: number;

    /**
     * Height of the image in pixels.
     */
    height?: number;

    /**
     * Type of the image asset.
     */
    imageType?: ImageType;

    constructor(self?: Image) {
        Object.assign(this, self);
    }
}
