import { Component } from 'preact';

/**
 * A simple hello world component
 */
class HelloWorld extends Component {
    render() {
        return <div id="hello-world">Hello Contacts!</div>;
    }
}

export default HelloWorld;
