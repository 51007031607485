// Types translated from iOS interface: https://tiny.amazon.com/o5fcny3u/codeamazpackContblobmainCont
export enum NextStepEnum {
    Redirect = 'Redirect',
    ActorDisassociated = 'ActorDisassociated',
    ActorMismatch = 'ActorMismatch',
    CustomerIdReAuthenticationRequired = 'CustomerIdReAuthenticationRequired',
}

export interface GetPhoneNumberVerificationURLResponse {
    nextStep?: NextStepEnum
    redirectURL: string
}
