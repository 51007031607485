import { Component } from 'preact';
import { Playlist, VisibilityType } from '../../types';

interface Props {
    userImage: string
    userPlaylists: Playlist[]
}

class ShareProfileImageCollage extends Component<Props> {
    render() {
        const profileImage = <div class='profile-image'>
            <music-vertical-item
                imageSrc={this.props.userImage}
                kind='circle'
                parentSize='small'
                role='editProfile'
                showActionButton={false}
            /></div>;
        const publicPlaylistImageUrls = this.props.userPlaylists
            .filter((playlist) => playlist.visibility === VisibilityType.PUBLIC)
            .flatMap((playlist) => playlist.images)
            .map((image) => image?.url)
            .filter((url) => url) || [];
        const firstPlaylistImage = publicPlaylistImageUrls[0]
            ? <div class='playlist-image first'><music-vertical-item
                imageSrc={publicPlaylistImageUrls[0]}
                kind='square'
                parentSize='small'
            /></div> : '';
        const secondPlaylistImage = publicPlaylistImageUrls[1]
            ? <div class='playlist-image second'><music-vertical-item
                imageSrc={publicPlaylistImageUrls[1]}
                kind='square'
                parentSize='small'
            /></div> : '';
        return <div class='profile-collage'>
            {firstPlaylistImage}
            {secondPlaylistImage}
            {profileImage}
        </div>;
    }
}
export default ShareProfileImageCollage;
