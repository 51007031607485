import { Component, createRef } from 'preact';

interface Props {
    id: string
    labelText: string
    onInput: (event: Event) => void
    value: string
}

/**
 * This class is a hack to extend MusicTextInput to allow an on change feature.
 * TODO: This should probably be moved into MusicTextInput for productionization, but is added here
 * for now to avoid needing changes to DMWebUIComponents
 */
export default class MusicTextInputWrapper extends Component<Props> {
    ref = createRef();

    private shadowRootUpdated(mutationList, observer) {
        const input = this.ref.current.shadowRoot.querySelector('input');
        if (input) {
            input.oninput = this.props.onInput;
            observer.disconnect();
        }
    }

    componentDidMount() {
        const config = { childList: true, subtree: true };
        const { shadowRoot } = this.ref.current;
        const observer = new MutationObserver(this.shadowRootUpdated.bind(this));
        observer.observe(shadowRoot, config);
    }

    render() {
        return <music-text-input id={this.props.id}
            value={this.props.value}
            ref={this.ref}
            label-text={this.props.labelText} />;
    }
}
