import { Component } from 'preact';
import { getCustomerInfo, shareProfile } from '../native/AppInterface';
import { getUser, getUserPlaylists } from '../services/firefly/FireFlyInterface';
import { User, Playlist } from '../types';
import {
    getStrings,
    LocalizedStrings,
    BROWSE_HOME,
} from '../util';
import ShareProfileImageCollage from './components/ShareProfileImageCollage';

interface State {
    user: User
    userPlaylists: Playlist[]
    strings: LocalizedStrings
}

class ShareProfilePage extends Component<object, State> {
    private async getCurrentProfile() {
        const {
            oAuthToken,
            deviceId,
            deviceType,
            locale,
        } = await getCustomerInfo();
        const userPromise = getUser(
            oAuthToken,
            deviceId,
            deviceType,
        );
        // TODO: This probably shouldn't need to be called here as we just got playlists on the previous page.
        // Calling for now for a simplification
        const userPlaylistsPromise = getUserPlaylists(
            oAuthToken,
            deviceId,
            deviceType,
            5,
        );
        const [user, userPlaylists] = await Promise.all([userPromise, userPlaylistsPromise]);
        this.setState({
            user,
            userPlaylists,
            strings: getStrings(locale),
        });
    }

    constructor(props) {
        super(props);
        this.getCurrentProfile();
    }

    private async share() {
        await shareProfile(this.state.user.id, this.state.user.name);
    }

    render() {
        // Don't render until state has been set
        if (!this.state.strings) {
            return <div class='loading-spinner-wrapper'>
                <music-icon size="xl" name="loader"/>
            </div>;
        }
        return <div id="share-profile" class='page-contents'>
            <header>
                <ShareProfileImageCollage
                    userImage={this.state.user.images[0].url}
                    userPlaylists={this.state.userPlaylists} />
                <h1 class='music-headline-2'>{this.state.strings.shareProfileHeader}</h1>
                <div class='secondary-text subtext'>{this.state.strings.shareProfileSubHeader}</div>
            </header>
            <div class='buttons'>
                <music-button class='primary-button'
                    ariaLabelText={this.state.strings.shareProfile}
                    variant='solid'
                    onClick={this.share.bind(this)}
                    size='large'>{this.state.strings.shareProfile}</music-button>
                <music-button class='primary-button'
                    ariaLabelText={this.state.strings.done}
                    variant='glass'
                    href={BROWSE_HOME}
                    size='large'>{this.state.strings.done}</music-button>
            </div>
        </div>;
    }
}

export default ShareProfilePage;
