import {
    User,
    Playlist,
    VisibilityType,
    UpdateUserParams,
    UpdatePlaylistParams,
} from '../../types';

import { BaseRequestParameters, FireFlyServiceClient } from './fireflyServiceClient';
import { FireFlyServiceHelper } from './fireflyServiceHelper';

const fireflyHelper = new FireFlyServiceHelper(new FireFlyServiceClient());

/**
 * Calls FireFly's getUser() API to get profile details of the user.
 *
 * @return {Promise} Promise that is resolved to User upon successful FireFly response
 */
export async function getUser(
    accessToken: string,
    deviceId: string,
    deviceType: string,
): Promise<User> {
    const baseRequestParams = {
        accessToken,
        deviceId,
        deviceType,
    } as BaseRequestParameters;
    return fireflyHelper.getUser(baseRequestParams);
}

/**
 * Calls FireFly's updateUser() API to update profile details of the user.
 *
 * @return {Promise} Promise that is resolved to User upon successful FireFly response
 */
export async function updateUser(
    accessToken: string,
    deviceId: string,
    deviceType: string,
    name?: string,
    visibility?: VisibilityType,
    playbackVisibility?: VisibilityType,
): Promise<User> {
    const updateUserRequest = {
        name,
        visibility,
        playbackVisibility,
    } as UpdateUserParams;
    const baseRequestParams = {
        accessToken,
        deviceId,
        deviceType,
    } as BaseRequestParameters;
    return fireflyHelper.updateUser(baseRequestParams, updateUserRequest);
}

/**
 * Calls FireFly's getUserPlaylists() API to get playlists of the user.
 *
 * @return {Promise} Promise that is resolved to Playlists[] upon successful FireFly response
 */
export async function getUserPlaylists(
    accessToken: string,
    deviceId: string,
    deviceType: string,
    limit?: number,
): Promise<Playlist[]> {
    const baseRequestParams = {
        accessToken,
        deviceId,
        deviceType,
    } as BaseRequestParameters;
    return fireflyHelper.getUserPlaylists(baseRequestParams, limit);
}

/**
 * Calls FireFly's updatePlaylist() API to get update non-track related metadate of the playlist.
 *
 * @return {Promise} Promise that is resolved to Playlist upon successful FireFly response.
 */
export async function updatePlaylist(
    accessToken: string,
    deviceId: string,
    deviceType: string,
    playlistId: string,
    title: string,
    description?: string,
    visibility?: VisibilityType,
): Promise<Playlist> {
    const updatePlaylistParams = {
        playlistId,
        title,
        description,
        visibility,
    } as UpdatePlaylistParams;
    const baseRequestParams = {
        accessToken,
        deviceId,
        deviceType,
    } as BaseRequestParameters;
    return fireflyHelper.updatePlaylist(baseRequestParams, updatePlaylistParams);
}
