import { ContactSourceType } from '.';

// Types translated from iOS interface: https://tiny.amazon.com/sgzn64s5/codeamazpackContblobmainCont
export enum AddressType {
    skype = 'skype',
    phoneNumber = 'phoneNumber',
    emailAddress = 'emailAddress',
    groupId = 'groupId',
}

export enum EmailType {
    home = 'Home',
    work = 'Work',
    other = 'Other',
    icloud = 'iCloud',
    custom = 'Custom',
}

export enum PhoneNumberType {
    home = 'Home',
    work = 'Work',
    iPhone = 'IPhone',
    mobile = 'Mobile',
    main = 'Main',
    homeFax = 'Home Fax',
    workFax = 'Work Fax',
    pager = 'Pager',
    other = 'Other',
    custom = 'Custom',
}

interface Address {
    value: string
    rawType?: AddressType
    type?: string
    addressId?: string
}

interface Email {
    email: string
    rawType?: EmailType
    type?: string
    displayTypeName?: string
}

interface Name {
    firstName?: string
    lastName?: string
    nickName?: string
    sourceNickName?: string
    pronunciationFirstName?: string
    pronunciationLastName?: string
    phoneticFirstName?: string
    phoneticLastName?: string
}

interface PhoneNumber {
    originalNumber?: string
    number: string
    obfuscatedPhoneNumber?: string
    rawType?: PhoneNumberType
    type?: string
    displayTypeName?: string
    isCOBOEnabled?: boolean
    addressId?: string
}

interface Preference {
    favourite?: boolean
    dropIn?: boolean
}

export interface Contact {
    id: string
    commsId?: string
    homeGroupCommsId?: string
    displayName?: string
    aor?: string
    isBlocked?: boolean
    canDropIn?: boolean
    canBeDroppedInOn?: boolean
    commsIds?: string[]
    serverContactId?: string
    sourceDeviceId?: string
    deviceContactId?: string
    contactSourceType?: ContactSourceType
    ownerCommsId?: string
    isLinked?: boolean
    referenceCommsId?: string
    referenceContactId?: string
    alexaEnabled: boolean
    isBulkImport?: boolean
    isChild?: boolean
    relationship?: string
    name: Name
    companyName?: string
    phoneNumbers?: PhoneNumber[]
    emails?: Email[]
    addresses?: Address[]
    isMerged?: boolean
    shouldMerge?: boolean
    shouldDisplay?: boolean
    mergedContactIdentifiers?: string[]
    deviceName?: string
    createdDate?: string
    modifiedDate?: string
    isContactOfChild?: boolean
    preference?: Preference
}
