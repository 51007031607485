import { Image } from './Image';
import { VisibilityType } from './VisibilityType';

export class Playlist {
    /**
     * Identifier associated with the playlist.
     */
    id: string;

    /**
     * The title of this playlist.
     */
    title: string;

    /**
     * The duration of this playlist in seconds.
     */
    duration: number;

    /**
     * The number of tracks within this playlist.
     */
    trackCount: number;

    /**
     * The visibility of this playlist.
     */
    visibility: VisibilityType;

    /**
     * Images associated with this playlist.
     */
    images: Image[];

    /**
     * The description of this playlist.
     */
    description?: string;

    constructor(self?: Playlist) {
        Object.assign(this, self);
    }
}
