import { Image } from './Image';
import { VisibilityType } from './VisibilityType';

export class User {
    /**
     * ProfileId of the User
     */
    id: string;

    /**
     * Name of this User
     */
    name: string;

    /**
     * Images of this User
     */
    images: Image[];

    /**
     * Profile Status of this User
     */
    visibility: VisibilityType;

    /**
     * PlaybackVisibility Status of this User
     * i.e. Allows/ Disallows sharing listening history
     */
    playbackVisibility?: VisibilityType;

    constructor(self?: User) {
        Object.assign(this, self);
    }
}
