// Types translated from iOS interface: https://tiny.amazon.com/t42j5yi3/codeamazpackContblobmainCont
export enum PreferenceKey {
    AMAZON_CONTACTS ='AMAZON_CONTACTS',
    AUTO_DISCOVERY = 'AUTO_DISCOVERY',
    CONTACT_IMPORT = 'CONTACT_IMPORT',
    PHONE_NUMBER_VERIFIED = 'PHONE_NUMBER_VERIFIED',
}

// More targets exist, but we should never need to access them, so limiting what's defined here.
export enum PreferenceTarget {
    ALL = 'ALL',
    AMAZON_MUSIC = 'AmazonMusic',
}

export enum PreferenceValue {
    ENABLED = 'enabled',
    DISABLED = 'disabled',
    SELECTIVE_IMPORT = 'selective-import',
}

// Recursive enums don't exist in TS. Closest approximation that should translate
export enum ContactsOwnerPreference {
    AMAZON_CONTACTS_ENABLED = 'AMAZON_CONTACTS.enabled',
    AMAZON_CONTACTS_DISABLED = 'AMAZON_CONTACTS.disabled',
    AUTO_DISCOVERY_ENALBED = 'AUTO_DISCOVERY.enabled',
    AUTO_DISCOVERY_DISABLED = 'AUTO_DISCOVERY.disabled',
    CONTACT_IMPORT_ENABLED = 'CONTACT_IMPORT.enabled',
    CONTACT_IMPORT_DISABLED = 'CONTACT_IMPORT.disabled',
    CONTACT_SELECTIVE_IMPORT = 'CONTACT_IMPORT.selective-import',
    PHONE_NUMBER_VERIFIED_ENABLED = 'PHONE_NUMBER_VERIFIED.enabled',
    PHONE_NUMBER_VERIFIED_DISABLED = 'PHONE_NUMBER_VERIFIED.disabled',
}
